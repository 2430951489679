import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  Stack,
  Grid,
  Card,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { getPayoutAmount } from "../services/dashboard_api";
import { parseCurrency } from "../utils/tableFunctions";
import { useUserContext } from "../context/userContext";
import LatestTransactions from "../components/dashboard/LatestTransactions";
import TransactionOverview from "../components/dashboard/TransactionOverview";
import moment from "moment";

const Dashboard = () => {
  const [loaded, setLoaded] = useState(false);
  const [amt, setAmt] = useState([]);

  const user = useUserContext();

  var today = moment();
  var begginingOfCurrentWeek = today
    .startOf("week")
    .add(1, "day")
    .format("MMM Do");

  const tooltips = {
    transactions: (
      <Typography variant="body2">
        This table contains the latest 5 transactions.
      </Typography>
    ),
    overview: (
      <Typography variant="body2">
        Compares transactions starting from {begginingOfCurrentWeek} till today
        against transactions on the same days last week
      </Typography>
    ),
  };

  useEffect(() => {
    if (user.access && !loaded) {
      getPayoutAmount(setAmt, setLoaded);
    }
  }, [user, loaded]);

  return (
    <>
      <Container>
        {user && user.access ? (
          <Grid container columns={{ xs: 1, sm: 4, md: 4 }}>
            <Grid item xs={1} md={2} sm={2}>
              <Container style={{ overflow: "hidden" }}>
                <Container>
                  <Typography variant="h3" sx={{ my: 3 }}>
                    Welcome Back
                  </Typography>

                  <Stack spacing={2}>
                    {loaded && amt.length > 0 && (
                      <Card sx={{ p: 2 }}>
                        <Stack
                          direction="row"
                          spacing={2}
                          sx={{
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: "400",
                            }}
                          >
                            Total Amount Payout by Today :
                          </Typography>

                          <Typography variant="h5" color="#112c4a">
                            {`${amt.currency} ${parseCurrency(amt.amount)}
                                                    `}
                          </Typography>
                        </Stack>
                      </Card>
                    )}
                  </Stack>
                </Container>
                <TransactionOverview user={user} tooltips={tooltips} />
              </Container>
            </Grid>
            <Grid item xs={1} md={2} sm={2}>
              <LatestTransactions user={user} tooltips={tooltips} />
            </Grid>
          </Grid>
        ) : (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Container>
    </>
  );
};

export default Dashboard;
