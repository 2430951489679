import PropTypes from "prop-types";
import React from "react";
// material
import { alpha, styled } from "@mui/material/styles";
import {
  Stack,
  AppBar,
  Toolbar,
  Grid,
  Button,
  Typography,
  Container,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import logo from "../images/OxpayLogo.png";
import AccountPopover from "./AccountPopover";
import useMediaQuery from "@mui/material/useMediaQuery";
import { MHidden } from "../components/@material-extend";
import { parseEmail } from "../utils/tableFunctions";
import { useUserContext } from "../context/userContext";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  // WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 1),
  // backgroundColor: "transparent",
  [theme.breakpoints.up("lg")]: {
    // width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    width: `100%`,
    alignItems: "center",
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  // width: "70%",
  // alignItems: "center",
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const hidden = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const navigate = useNavigate();
  const user = useUserContext();

  return (
    <RootStyle>
      <Container>
        <ToolbarStyle>
          <MHidden width="lgUp">
            <Button onClick={onOpenSidebar}>
              <MenuIcon sx={{ mr: 1, color: "text.primary" }} />
            </Button>
          </MHidden>
          {hidden ? null : (
            <Grid
              container
              spacing={2}
              direction="row"
              columns={8}
              alignItems="center"
              sx={{ width: "80%" }}
            >
              <Grid item md={1} xs={1}>
                <img
                  onClick={() => {
                    navigate("/dashboard");
                  }}
                  src={logo}
                  alt="logo"
                  style={{
                    width: "70%",
                    height: "70%",
                    cursor: "pointer",
                  }}
                />
              </Grid>
              <Grid item md={1} xs={1}>
                <Button href="/transaction">
                  <Typography variant="body" color="black">
                    Transactions
                  </Typography>
                </Button>
              </Grid>
              <Grid item md={1} xs={1}>
                <Button href="/settlement">
                  <Typography variant="body" color="black">
                    Settlements
                  </Typography>
                </Button>
              </Grid>

              <Grid item md={1} xs={1}>
                <Button href="/report">
                  <Typography variant="body" color="black">
                    Reports
                  </Typography>
                </Button>
              </Grid>

              {/* <Grid item md={1} xs={1}>
                <Button href="/insight">
                  <Typography variant="body" color="black">
                    Insight
                  </Typography>
                </Button>
              </Grid> */}
            </Grid>
          )}
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            style={{
              marginLeft: "auto",
              marginRight: "0",
            }}
          >
            {hidden ? null : (
              <Typography variant="body" color="#112c4a" fontWeight="600">
                Hello, {user?.email && parseEmail(user.email)}
              </Typography>
            )}
            <AccountPopover />
          </Stack>
        </ToolbarStyle>
      </Container>
    </RootStyle>
  );
}
