import PropTypes from "prop-types";
// material
import { Box } from "@mui/material";
import logo from "../images/OxpayLogo.png";

// ----------------------------------------------------------------------

const Logo = ({ sx }) => {
  return (
    <Box component="img" src={logo} sx={{ width: 55, height: 55, ...sx }} />
  );
};

Logo.propTypes = {
  sx: PropTypes.object,
};

export default Logo;
