import api from "./api";

export async function setUserRole(user, setUser) {
  const url = "/dashboard/merchant/account/get_roles/";
  return await api
    .get(url)
    .then((res) => {
      setUser({
        ...user,
        role: res.data.permission,
      });
      if (localStorage.getItem("authorisedUser") === null)
        window.location.reload();
      localStorage.setItem("authorisedUser", true);
    })
    .catch(() => {
      localStorage.setItem("authorisedUser", false);
    });
}

export async function getUserRoleStatus() {
  const url = "/dashboard/merchant/account/get_roles/";
  await api
    .get(url)
    .then((res) => {
      if (res.status === 200) {
        if (localStorage.getItem("authorisedUser") === null)
          window.location.reload();
        localStorage.setItem("authorisedUser", true);
      } else localStorage.setItem("authorisedUser", false);
    })
    .catch(() => {
      localStorage.setItem("authorisedUser", false);
    });
}

export async function getNextPage({ url, filter }) {
  const res = await api.post(url, {
    ...filter,
  });
  return res.data;
}

export function getBrandColour(setColour) {
  const url = "/dashboard/merchant/brand/colour_code/";
  api.get(url).then((response) => {
    setColour(response.data);
  });
}

export function cleanJsonObj(obj) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName].length === 0
    ) {
      delete obj[propName];
    }
  }
  return obj;
}
