import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { startCase } from "lodash";

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
};

export default function TableView({
  rows,
  link,
  checkbox = false,
  getNext,
  count,
  query,
  setOpenDeleteDialog,
  setOpenEditDialog,
  setSelectedIDs,
  urlId = "id", // must be an array if not id
  clickable = true,
}) {
  const headCells = getHeadCells() || [
    {
      id: null,
      label: <>No data was found.</>,
      align: "center",
    },
  ];

  function getHeadCells() {
    let tableHead = [];
    if (rows && rows[0]) {
      Object.keys(rows[0]).forEach((key) => {
        if (key !== "id") {
          tableHead.push({ id: key, label: startCase(key) });
        }
      });

      return tableHead;
    }
    return false;
  }

  const replaceComma = (value) => {
    // string of amounts more than 4 digits
    if (typeof value === "string" && value.includes(","))
      return parseFloat(value.replace(/,/g, ""));
    // string of amounts, convert to float
    else if (typeof value === "string" && value.includes("."))
      return parseFloat(value);
    return value;
  };

  function descendingComparator(a, b, orderBy) {
    var firstVar = replaceComma(a[orderBy]);
    var secVar = replaceComma(b[orderBy]);

    if (secVar < firstVar) {
      return -1;
    }
    if (secVar > firstVar) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    if (query) {
      return array.filter((item) => {
        for (const [key, value] of Object.entries(item)) {
          if (
            value &&
            value.toString().toLowerCase().startsWith(query.toLowerCase())
          ) {
            return value;
          }
        }
      });
    }
    return stabilizedThis.map((el) => el[0]);
  }

  function EnhancedTableHead(props) {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells[0].id !== null && checkbox && (
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            </TableCell>
          )}
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.align ? headCell.align : "center"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={{ ...visuallyHidden }}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const EnhancedTableToolbar = (props) => {
    // if (numSelected > 0) {
    //   return (
    //     <Toolbar
    //       sx={{
    //         pl: { sm: 2 },
    //         pr: { xs: 1, sm: 1 },
    //         ...(numSelected > 0 && {
    //           bgcolor: (theme) =>
    //             alpha(
    //               theme.palette.primary.main,
    //               theme.palette.action.activatedOpacity
    //             ),
    //         }),
    //       }}
    //     >
    //       {numSelected > 0 ? (
    //         <Typography
    //           sx={{ flex: "1 1 100%" }}
    //           color="inherit"
    //           variant="subtitle1"
    //           component="div"
    //         >
    //           {numSelected} selected
    //         </Typography>
    //       ) : (
    //         <Typography
    //           sx={{ flex: "1 1 100%" }}
    //           variant="h6"
    //           id="tableTitle"
    //           component="div"
    //         >
    //           {" "}
    //         </Typography>
    //       )}
    //     </Toolbar>
    //   );
    // }
    return <></>;
  };

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  function EnhancedTable() {
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = rows.map((n) => n.id);
        setSelected(newSelecteds);
        setSelectedIDs(newSelecteds);
        return;
      }
      setSelected([]);
      setSelectedIDs([]);
    };

    const handleClick = (event, name) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);
      setSelectedIDs(newSelected);
    };

    const handleLink = (event, id, moreThanOneId = false) => {
      // allows highlighting of text in the table
      let selection = window.getSelection().toString();
      if (selection.length < 1) {
        event.preventDefault();
        if (link && link.includes("~id~")) {
          let newlink = link.replace("~id~", `/${id}/`);
          window.open(newlink);
        }
        if (moreThanOneId) window.open(`${link}/${id.join("/")}/`);
        else window.open(`${link}/${id}/`);
      }
    };

    const handleChangePage = (event, newPage) => {
      if (typeof getNext !== "undefined") {
        getNext();
      }
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    return (
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              // size={"small"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                   rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        sx={{ cursor: clickable && "pointer" }}
                      >
                        {checkbox && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                              onClick={(event) => handleClick(event, row.id)}
                            />
                          </TableCell>
                        )}

                        {Object.keys(row).map((oneKey, i) => {
                          if (oneKey === "id") {
                            return null;
                          } else
                            return (
                              <TableCell
                                onClick={(e) => {
                                  if (!clickable) return null;
                                  if (urlId !== "id") {
                                    let mappedField = urlId.map(
                                      (item) => row[item]
                                    );
                                    handleLink(e, mappedField, true);
                                  } else handleLink(e, row.id);
                                }}
                                key={i}
                                align="center"
                              >
                                {row[oneKey]}
                              </TableCell>
                            );
                        })}
                      </TableRow>
                    );
                  })}
                {/* {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    );
  }

  return EnhancedTable();
}
