import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Container,
  Box,
  Drawer,
  Stack,
  FormControl,
  Select as MuiSelect,
  MenuItem,
  Typography,
  InputLabel,
  Slider,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import useMediaQuery from "@mui/material/useMediaQuery";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import moment from "moment";
import { capitalise } from "../../utils/tableFunctions";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Search } from "@mui/icons-material";

export const SingleSelectType = ({
  id = false,
  options,
  selected,
  setSelected,
  label = "",
  param = false,
  required = false,
  reset,
  size,
  sx,
}) => {
  useEffect(() => {
    if (reset) {
      setSelected("");
    }
  }, [reset]);

  const handleChange = (event) => {
    // to store new value in the same piece of state. search fParams for example.
    if (param !== false) {
      let newParam = {};
      newParam[param] = event.target.value;
      setSelected({ ...selected, ...newParam });
    } else setSelected(event.target.value);
  };
  return (
    <FormControl
      fullWidth
      sx={sx ?? { mb: 3 }}
      required={required}
      size={size ?? ""}
      margin="normal"
    >
      {label ? <InputLabel>{label}</InputLabel> : ""}
      <MuiSelect
        value={
          param
            ? typeof selected[param] !== "undefined"
              ? selected[param]
              : ""
            : selected
        }
        onChange={handleChange}
        label={label && label}
      >
        {options && options.length > 0 ? (
          options.map((item) => (
            <MenuItem key={id ? item.id : item} value={id ? item.id : item}>
              {capitalise(
                id ? (item.name ? item.name : item.business_name) : item
              )}
            </MenuItem>
          ))
        ) : (
          <MenuItem>Options unavailable</MenuItem>
        )}
      </MuiSelect>
    </FormControl>
  );
};

export const InputType = ({ fInput, setFInput, reset }) => {
  useEffect(() => {
    if (reset) setFInput("");
  }, [reset, setFInput]);
  return (
    <TextField
      margin="dense"
      type="text"
      fullWidth
      variant="standard"
      value={fInput}
      onChange={(event) => {
        setFInput(event.target.value);
      }}
    />
  );
};

export const DateRangeType = ({ date, setDate, reset }) => {
  useEffect(() => {
    if (reset) setDate([moment().subtract(3, "days"), moment()]);
  }, [reset, setDate]);
  return (
    <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          label="Start Date"
          disableFuture
          value={date[0]}
          onChange={(newValue) => {
            setDate((prev) => [newValue, prev[1]]);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          label="End Date"
          disableFuture
          value={date[1]}
          minDate={date[0]}
          onChange={(newValue) => {
            setDate((prev) => [prev[0], newValue]);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </Stack>
  );
};

export const MultiCheckboxesType = ({
  items,
  checkedValues,
  setCheckedValues,
  reset,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reset) setCheckedValues([]);
  }, [reset, setCheckedValues]);
  return items && items.length > 0 ? (
    <FormGroup row>
      {items.map(
        (item) =>
          item && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedValues.includes(item)}
                  onChange={(event) => {
                    setCheckedValues((prev) =>
                      event.target.checked
                        ? [...prev, item]
                        : [...prev.filter((i) => i !== item)]
                    );
                  }}
                />
              }
              value={item}
              label={item}
              key={item}
            />
          )
      )}
    </FormGroup>
  ) : (
    <Typography variant="body">Options unavailable.</Typography>
  );
};

export const MinMaxType = ({ minVal, maxVal, setValue, value, reset }) => {
  const [range, setRange] = useState([value[0] || minVal, value[1] || maxVal]);
  const handleChange = (event, newValue) => {
    setRange(newValue);
    setValue(newValue);
  };

  const marks = [
    { value: minVal, label: minVal },
    { value: maxVal, label: maxVal },
  ];

  useEffect(() => {
    if (reset) {
      setRange([minVal, maxVal]);
      setValue([null, null]);
    }
  }, [reset, minVal, maxVal]);
  return minVal !== undefined && maxVal ? (
    <Box sx={{ mr: 5 }}>
      <Slider
        min={minVal}
        max={maxVal}
        onChange={handleChange}
        value={range}
        marks={marks}
        valueLabelDisplay="auto"
      />
    </Box>
  ) : (
    <Typography variant="body">Options unavailable.</Typography>
  );
};

const animatedComponents = makeAnimated();

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    padding: 10,
    zIndex: 4,
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
};

export const MultiSelect = ({
  options,
  selectedValues,
  setSelectedValues,
  reset,
}) => {
  useEffect(() => {
    if (reset) {
      setSelectedValues([]);
    }
  }, [reset, setSelectedValues]);

  return (
    <Select
      closeMenuOnSelect={false}
      components={animatedComponents}
      value={options.filter(({ value }) => selectedValues.includes(value))}
      isMulti
      options={options}
      styles={customStyles}
      onChange={(event) => {
        setSelectedValues(event.map((selected) => selected.value));
      }}
      menuPortalTarget={document.body}
    />
  );
};

const Filter = ({ renderContent, type, onSubmit, setReset }) => {
  const mobileHidden = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleFilterSubmit = () => {
    onSubmit();
    onCloseDrawer();
  };

  const onOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const { pathname } = useLocation();

  useEffect(() => {
    if (openDrawer) {
      onCloseDrawer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      <Button variant="outlined" onClick={onOpenDrawer}>
        <Search sx={{ mr: 1 }} />
        {mobileHidden ? "Search" : null}
      </Button>
      <Drawer
        open={openDrawer}
        onClose={onCloseDrawer}
        anchor="right"
        PaperProps={{
          sx: { width: mobileHidden ? "40%" : "100%" },
        }}
      >
        <Container>
          {renderContent()}
          <Box sx={{ height: 20 }} />
          <Stack
            direction="row"
            style={{
              display: "flex",
              position: "relative",
              justifyContent: "center",
            }}
          >
            <Button
              style={{
                position: "absolute",
                left: 0,
                textDecoration: "underline",
              }}
              onClick={() => {
                setReset(true);
              }}
            >
              Reset
            </Button>
            <Stack direction="row" style={{ display: "flex" }}>
              <Button onClick={onCloseDrawer}>Cancel</Button>
              <Button
                onClick={handleFilterSubmit}
                variant="contained"
                color="primary"
              >
                Apply Filter
              </Button>
            </Stack>
          </Stack>
          <Box sx={{ height: 30 }} />
        </Container>
      </Drawer>
    </>
  );
};

export default Filter;
