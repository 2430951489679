import React from "react";
import { Container, Typography, Stack, Fab, Box } from "@mui/material";
import ScrollTop from "../components/ScrollTop";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CustomTooltip from "../components/CustomTooltip";
import TransactionList from "../components/transactions/TransactionList";

const Transactions = (props) => {
  const tooltipContent = (
    <Box>
      <Typography variant="body1">
        Review all transactions from my terminals
      </Typography>
    </Box>
  );

  return (
    <>
      <Container>
        <Stack
          direction="row"
          spacing={2}
          style={{
            display: "flex",
            alignItems: "center",
          }}
          sx={{ mb: 3 }}
          id="back-to-top-anchor"
        >
          <Typography variant="h3">Transactions</Typography>
          <CustomTooltip content={tooltipContent} placement="bottom" />
        </Stack>
        <TransactionList />
      </Container>
      <ScrollTop {...props}>
        <Fab
          sx={[
            {
              "&:hover": {
                color: "#112C4A",
                bgcolor: "#fff",
              },
            },
            { bgcolor: "#112C4A", color: "#fff" },
          ]}
          size="large"
          aria-label="Scroll Back to Top"
        >
          <ArrowUpwardIcon />
        </Fab>
      </ScrollTop>
    </>
  );
};

export default Transactions;
