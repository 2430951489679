import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { apiLogout } from "../services/auth.service";
import { getUserRoleStatus } from "../services/services";

const Loading = () => {
  const checkAuthorisedUser = () => {
    if (localStorage.getItem("user")) {
      getUserRoleStatus();
    }
  };

  var i = 1; //  set your counter to 1

  function myLoop() {
    //  create a loop function
    setTimeout(function () {
      //  call a 3s setTimeout when the loop is called
      checkAuthorisedUser();
      i++; //  increment the counter
      if (i < 10) {
        //  if the counter < 10, call the loop function
        myLoop(); //  ..  again which will trigger another
      } //  ..  setTimeout()
      else if (i >= 10) apiLogout();
    }, 1500);
  }

  useEffect(() => {
    myLoop();
  }, []);
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loading;
