import api from "./api";

export function getChartOverview(setOverview, setLoaded) {
  const url = "/dashboard/merchant/overview/";
  api
    .get(url)
    .then((response) => {
      setOverview(response.data);
    })
    .finally(() => setLoaded(true));
}

export function getDashboardTransactions(setReponse, setLoaded) {
  const url = "/dashboard/merchant/transaction_summary/";
  api
    .get(url)
    .then((response) => {
      setReponse(response.data);
    })
    .finally(() => setLoaded(true));
}

export function getPayoutAmount(setReponse, setLoaded) {
  const url = "/dashboard/merchant/payout_amount/";
  api
    .get(url)
    .then((response) => {
      setReponse(response.data);
    })
    .finally(() => setLoaded(true));
}
