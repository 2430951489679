import fileDownload from "js-file-download";
import { showErrorMsg } from "../utils/toast";
import api from "./api";
import { cleanJsonObj } from "./services";

export async function getReportList(
  setResponse,
  setLoaded,
  filter,
  download = "false"
) {
  const url = "/dashboard/merchant/report/filter/";
  filter = cleanJsonObj(filter);
  return await api
    .post(url, {
      ...filter,
      download: download ? "true" : "false",
    })
    .then((res) => {
      setResponse(res.data);
    })
    .catch((err) => {
      showErrorMsg(`Status ${err.response.status}, failed to get data`);
    })
    .finally(() => setLoaded(true));
}

export function getReportFilterParams(setParams, setLoaded) {
  const url = "/dashboard/merchant/report/filter_conditions/";
  api
    .get(url)
    .then((response) => {
      setParams(response.data);
    })
    .catch((err) => {
      showErrorMsg(
        `Status ${err.response.status}, failed to get filter choices`
      );
    })
    .finally(() => setLoaded(true));
}

export async function getReportCSV(filter) {
  const url = "/dashboard/merchant/report/filter/";
  filter = cleanJsonObj(filter);
  const data = await api.post(url, {
    download: "true",
    ...filter,
  });
  return data.data;
}

export function getReportDetails(
  id,
  setSummary,
  setProfile,
  setReport,
  setLoaded
) {
  const url = `/dashboard/merchant/report/details/${id}/`;
  api
    .get(url)
    .then((response) => {
      setSummary(response.data.sttreport_summary);
      setProfile(response.data.sttreport_profile);
      setReport(response.data);
    })
    .catch((err) => {
      showErrorMsg(`Status ${err.response.status}, failed to get report page`);
    })
    .finally(() => setLoaded(true));
}

export function getReportSettlements(id, setResponse, setLoaded) {
  const url = `/dashboard/merchant/report/settlement_details/${id}/`;
  api
    .get(url)
    .then((res) => {
      setResponse(res.data);
    })
    .catch((err) => {
      showErrorMsg(
        `Status ${err.response.status}, failed to get report settlement`
      );
    })
    .finally(() => setLoaded(true));
}

export function getReportDeposit(id, setResponse, setLoaded) {
  const url = `/dashboard/merchant/report/deposit/details/${id}/`;
  api
    .get(url)
    .then((res) => {
      setResponse(res.data);
    })
    .catch((err) => {
      showErrorMsg(
        `Status ${err.response.status}, failed to get report security deposit`
      );
    })
    .finally(() => setLoaded(true));
}

export function getDownloadReport(id, report_name, report_date, brand) {
  const url = `/dashboard/merchant/report/report_download/${id}/`;
  api
    .get(url, {
      responseType: "blob",
    })
    .then((response) => {
      fileDownload(
        response.data,
        `${report_name}_${report_date}_${brand}.xlsx`
      );
    });
}
