import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  Grid,
  Container,
  Typography,
  Stack,
  Select,
  TextField,
  MenuItem,
  IconButton,
  Backdrop,
  CircularProgress,
  Card,
  Button,
  ButtonGroup,
  Dialog,
  FormGroup,
  Divider,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import {
  parseDate,
  parseTime,
  parseCurrency,
  parseBrandName,
  parseDateTime,
} from "../../utils/tableFunctions";
import { Box } from "@mui/system";
import { canBeRefunded, canBeVoided } from "../../utils/void_refund_logic";
import { Close } from "@mui/icons-material";
import {
  getTransactionDetails,
  postRefundTransaction,
} from "../../services/transactions_api";
import { isObjectEmpty } from "../../utils/appUtils";

const TransactionDetails = () => {
  const [details, setDetails] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isRefund, setIsRefund] = useState(false);
  const [isPartial, setIsPartial] = useState(false);
  const [comment, setComment] = useState("");
  const [partialAmount, setPartialAmount] = useState(0.0);
  const [refs, setRefs] = useState([]);
  const [isRefundOpen, setRefundOpen] = useState(false);

  const handleButtonClick = () => {
    window.close();
  };

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const { id } = useParams();

  useEffect(() => {
    getTransactionDetails(id, setDetails, setRefs, setLoaded);
  }, [id]);

  const handleVoidOpen = () => {
    setIsOpen(true);
    setIsRefund(false);
  };

  const handleRefundOpen = () => {
    setIsOpen(true);
    setIsRefund(true);
  };

  const handleDialogClose = () => {
    setIsOpen(false);
  };

  const handlePartialOpen = () => {
    setIsPartial(true);
  };

  const handlePartialClose = () => {
    setIsPartial(false);
  };

  const handleRefundSubmit = () => {
    if (comment === "") {
      alert("Comment is required!");
    } else {
      if (isPartial) {
        if (isNaN(partialAmount) || partialAmount === "") {
          alert("Amount is required!");
        } else if (partialAmount > details.base_amount) {
          alert("Refund amount must be smaller than the net amount!");
        } else {
          postRefundTransaction(
            id,
            partialAmount,
            comment,
            setDetails,
            setRefs,
            setLoaded
          );
          setIsOpen(false);
          setIsPartial(false);
          setPartialAmount("");
          setComment("");
        }
      } else {
        postRefundTransaction(
          id,
          details.base_amount,
          comment,
          setDetails,
          setRefs,
          setLoaded
        );
        setIsOpen(false);
        setIsPartial(false);
        setPartialAmount("");
        setComment("");
      }
    }
  };

  const handleVoidSubmit = () => {
    // if (comment === "") {
    //   alert("A comment is required!");
    // } else {
    //   const pk_list = JSON.stringify([id]);
    //   postVoidTransactions(pk_list, comment, false, details.oxpay_id, id);
    //   setIsOpen(false);
    // }
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handlePartialChange = (event) => {
    setPartialAmount(parseFloat(event.target.value));
  };

  const renderRefundDialog = () => {
    return (
      <Dialog open={isOpen} onClose={handleDialogClose} fullWidth>
        <Stack
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pr: 2,
            alignItems: "center",
            bgcolor: "primary.main",
          }}
        >
          <DialogTitle sx={{ color: "#fff" }}>Confirm Refund</DialogTitle>
          <Box>
            <Button
              variant="contained"
              color="error"
              onClick={handleDialogClose}
            >
              Cancel
            </Button>
          </Box>
        </Stack>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              alignItems: "center",
            }}
          >
            <FormGroup style={{ width: "70%" }}>
              <Typography variant="body1" sx={{ mt: "25px" }}>
                Choose your refund type
              </Typography>
              <Select
                label="Refund Type"
                style={{ minWidth: "100%" }}
                value={isPartial ? 2 : 1}
              >
                <MenuItem value={1} onClick={handlePartialClose}>
                  Full
                </MenuItem>
                <MenuItem value={2} onClick={handlePartialOpen}>
                  Partial
                </MenuItem>
              </Select>

              {isPartial ? (
                <>
                  <Typography variant="body1" sx={{ mt: "15px" }}>
                    Partial Refund Amount *
                  </Typography>
                  <TextField
                    required
                    id="standard-basic"
                    variant="standard"
                    onChange={handlePartialChange}
                    sx={{
                      mb: "10px",
                      minWidth: "100%",
                    }}
                    type="number"
                  />
                </>
              ) : null}

              <Typography variant="body1" sx={{ mt: "15px" }}>
                Comments *
              </Typography>
              <TextField
                required
                id="standard-basic"
                variant="standard"
                onChange={handleCommentChange}
                sx={{ mb: "10px", minWidth: "100%" }}
              />
            </FormGroup>

            <Button
              variant="contained"
              sx={{ display: "flex", ml: "auto", mt: 3 }}
              onClick={handleRefundSubmit}
            >
              Submit
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

  const renderDialogContent = () => {
    return (
      <>
        <Dialog open={isOpen} onClose={handleDialogClose} fullWidth>
          <Stack
            style={{
              alignItems: "center",
              width: "50%",
              backgroundColor: "#fff",
            }}
          >
            <IconButton
              size="large"
              onClick={handleDialogClose}
              style={{ alignSelf: "end" }}
            >
              <Close fontSize="large" />
            </IconButton>

            <FormGroup style={{ width: "70%" }}>
              {isRefund ? (
                <>
                  <Typography variant="body1" sx={{ mt: "25px" }}>
                    Choose your refund type
                  </Typography>
                  <Select label="Refund Type" style={{ minWidth: "100%" }}>
                    <MenuItem value={1} onClick={handlePartialClose}>
                      Full
                    </MenuItem>
                    <MenuItem value={2} onClick={handlePartialOpen}>
                      Partial
                    </MenuItem>
                  </Select>

                  {isPartial ? (
                    <>
                      <Typography variant="body1" sx={{ mt: "15px" }}>
                        Partial Refund Amount
                      </Typography>
                      <TextField
                        required="true"
                        id="standard-basic"
                        label="Required"
                        variant="standard"
                        onChange={handlePartialChange}
                        sx={{
                          mb: "10px",
                          minWidth: "100%",
                        }}
                      />
                    </>
                  ) : null}

                  <Typography variant="body1" sx={{ mt: "15px" }}>
                    Comments
                  </Typography>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    onChange={handleCommentChange}
                    sx={{ mb: "10px", minWidth: "100%" }}
                    label="Required *"
                  />
                  <Button
                    text="Submit"
                    large="true"
                    onClick={handleRefundSubmit}
                    style={{
                      display: "block",
                      margin: "0 auto",
                    }}
                  />
                </>
              ) : (
                <>
                  <Typography variant="body1" sx={{ mt: "15px" }}>
                    Comments
                  </Typography>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    onChange={handleCommentChange}
                    sx={{ mb: "10px", minWidth: "100%" }}
                    label="Required *"
                  />
                  <Button
                    text="Submit"
                    large="true"
                    onClick={handleVoidSubmit}
                    style={{
                      display: "block",
                      margin: "0 auto",
                    }}
                  />
                </>
              )}
            </FormGroup>
          </Stack>
        </Dialog>
      </>
    );
  };

  const renderTransCard = (detail) => {
    return (
      <Box>
        <Grid
          container
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 2, sm: 2, md: 2 }}
          sx={{ mt: 5 }}
        >
          <Grid item xs={6} md={6}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6} md={5}>
                <Typography variant="subtitle1">Transaction ID</Typography>
              </Grid>
              <Grid
                item
                xs={6}
                md={7}
                sx={{
                  overflowWrap: "normal",
                  wordBreak: "break-word",
                }}
              >
                {detail.id}
              </Grid>
              <Grid item xs={6} md={5}>
                <Typography variant="subtitle1">Merchant Name</Typography>
              </Grid>
              <Grid item xs={6} md={7}>
                {detail.merchant_name}
              </Grid>
              <Grid item xs={6} md={5}>
                <Typography variant="subtitle1">MCPTid</Typography>
              </Grid>
              <Grid item xs={6} md={7}>
                {detail.mcptid}
              </Grid>
              <Grid item xs={6} md={5}>
                <Typography variant="subtitle1">Brand</Typography>
              </Grid>
              <Grid item xs={6} md={7}>
                {parseBrandName(detail.brand?.name)}
              </Grid>
              <Grid item xs={6} md={5}>
                <Typography variant="subtitle1">Base Amount</Typography>
              </Grid>
              <Grid item xs={6} md={7}>
                {detail.base_currency &&
                  `${detail.base_currency} ${parseCurrency(
                    detail.base_amount
                  )}`}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={6}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6} md={5}>
                <Typography variant="subtitle1">Transaction Time</Typography>
              </Grid>
              <Grid item xs={6} md={7}>
                {`${parseDateTime(detail.transaction_datetime)}`}
              </Grid>
              <Grid item xs={6} md={5}>
                <Typography variant="subtitle1">Transaction Type</Typography>
              </Grid>
              <Grid item xs={6} md={7}>
                {detail.transaction_type}
              </Grid>
              <Grid item xs={6} md={5}>
                <Typography variant="subtitle1">Status</Typography>
              </Grid>
              <Grid item xs={6} md={7}>
                {detail.transaction_state}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {detail.transaction_info && !isObjectEmpty(detail.transaction_info) && (
          <>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6">VISA Installment</Typography>
            <Box sx={{ height: 12 }} />
            <Grid
              container
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 2, sm: 2, md: 2 }}
              sx={{ mt: 16 }}
            >
              <Grid item xs={6} md={6}>
                <Grid container spacing={2} alignItems="center">
                  {/* V Plan ID */}
                  <Grid item xs={6} md={5}>
                    <Typography variant="subtitle1">V Plan ID</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={7}
                    sx={{
                      overflowWrap: "normal",
                      wordBreak: "break-word",
                    }}
                  >
                    {detail?.transaction_info?.v_plan_id
                      ? detail?.transaction_info?.v_plan_id
                      : "-"}
                  </Grid>
                  {/* Auth Code */}
                  <Grid item xs={6} md={5}>
                    <Typography variant="subtitle1">Auth Code</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={7}
                    sx={{
                      overflowWrap: "normal",
                      wordBreak: "break-word",
                    }}
                  >
                    {detail?.transaction_info?.auth_code
                      ? detail?.transaction_info?.auth_code
                      : "-"}
                  </Grid>

                  {/* Payment Account Reference */}
                  <Grid item xs={6} md={5}>
                    <Typography variant="subtitle1">
                      Payment Account Reference
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={7}
                    sx={{
                      overflowWrap: "normal",
                      wordBreak: "break-word",
                    }}
                  >
                    {detail?.transaction_info?.payment_account_reference
                      ? detail?.transaction_info?.payment_account_reference
                      : "-"}
                  </Grid>

                  {/* Number of Installment */}
                  <Grid item xs={6} md={5}>
                    <Typography variant="subtitle1">
                      Number of Installment
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={7}
                    sx={{
                      overflowWrap: "normal",
                      wordBreak: "break-word",
                    }}
                  >
                    {detail?.transaction_info?.number_of_installments
                      ? detail?.transaction_info?.number_of_installments
                      : "-"}
                  </Grid>
                  {/* Installment Frequency */}
                  <Grid item xs={6} md={5}>
                    <Typography variant="subtitle1">
                      Installment Frequency
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={7}
                    sx={{
                      overflowWrap: "normal",
                      wordBreak: "break-word",
                    }}
                  >
                    {detail?.transaction_info?.installment_frequency
                      ? detail?.transaction_info?.installment_frequency
                      : "-"}
                  </Grid>
                  {/* Selected Plan Name */}
                  <Grid item xs={6} md={5}>
                    <Typography variant="subtitle1">
                      Selected Plan Name
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={7}
                    sx={{
                      overflowWrap: "normal",
                      wordBreak: "break-word",
                    }}
                  >
                    {detail?.transaction_info?.selected_plan_name
                      ? detail?.transaction_info?.selected_plan_name
                      : "-"}
                  </Grid>

                  {/* Selected Plan Type */}
                  <Grid item xs={6} md={5}>
                    <Typography variant="subtitle1">
                      Selected Plan Type
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={7}
                    sx={{
                      overflowWrap: "normal",
                      wordBreak: "break-word",
                    }}
                  >
                    {detail?.transaction_info?.selected_plan_type
                      ? detail?.transaction_info?.selected_plan_type
                      : "-"}
                  </Grid>
                  {/* First Installment Fee */}
                  <Grid item xs={6} md={5}>
                    <Typography variant="subtitle1">
                      First Installment Fee
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={7}
                    sx={{
                      overflowWrap: "normal",
                      wordBreak: "break-word",
                    }}
                  >
                    {detail.base_currency &&
                      `${detail.base_currency} ${parseCurrency(
                        detail?.transaction_info?.first_installment_fee
                      )}`}
                  </Grid>
                  {/* First Installment Amount */}
                  <Grid item xs={6} md={5}>
                    <Typography variant="subtitle1">
                      First Installment Amount
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={7}
                    sx={{
                      overflowWrap: "normal",
                      wordBreak: "break-word",
                    }}
                  >
                    {detail.base_currency &&
                      `${detail.base_currency} ${parseCurrency(
                        detail?.transaction_info?.first_installment_amount
                      )}`}
                  </Grid>
                  {/* Selected Plan Name */}
                  <Grid item xs={6} md={5}>
                    <Typography variant="subtitle1">
                      First Installment Upfront Fee
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={7}
                    sx={{
                      overflowWrap: "normal",
                      wordBreak: "break-word",
                    }}
                  >
                    {detail.base_currency &&
                      `${detail.base_currency} ${parseCurrency(
                        detail?.transaction_info?.first_installment_upfront_fee
                      )}`}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={6}>
                <Grid container spacing={2} alignItems="center">
                  {/* Promotion Code */}
                  <Grid item xs={6} md={5}>
                    <Typography variant="subtitle1">Promotion Code</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={7}
                    sx={{
                      overflowWrap: "normal",
                      wordBreak: "break-word",
                    }}
                  >
                    {detail?.transaction_info?.promotion_code
                      ? detail?.transaction_info?.promotion_code
                      : "-"}
                  </Grid>
                  {/*  Promotion ID */}
                  <Grid item xs={6} md={5}>
                    <Typography variant="subtitle1">Promotion ID</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={7}
                    sx={{
                      overflowWrap: "normal",
                      wordBreak: "break-word",
                    }}
                  >
                    {detail?.transaction_info?.promotion_id
                      ? detail?.transaction_info?.promotion_id
                      : "-"}
                  </Grid>
                  {/* Total Plan Cost */}
                  <Grid item xs={6} md={5}>
                    <Typography variant="subtitle1">Total Plan Cost</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={7}
                    sx={{
                      overflowWrap: "normal",
                      wordBreak: "break-word",
                    }}
                  >
                    {detail.transaction_currency &&
                      `${detail.transaction_currency} ${parseCurrency(
                        detail?.transaction_info?.total_plan_cost
                      )}`}
                  </Grid>
                  {/* Total Fees */}
                  <Grid item xs={6} md={5}>
                    <Typography variant="subtitle1">Total Fees</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={7}
                    sx={{
                      overflowWrap: "normal",
                      wordBreak: "break-word",
                    }}
                  >
                    {detail.base_currency &&
                      `${detail.base_currency} ${parseCurrency(
                        detail?.transaction_info?.total_fees
                      )}`}
                  </Grid>
                  {/* Total Recurring Fees */}
                  <Grid item xs={6} md={5}>
                    <Typography variant="subtitle1">
                      Total Recurring Fees
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={7}
                    sx={{
                      overflowWrap: "normal",
                      wordBreak: "break-word",
                    }}
                  >
                    {detail.base_currency &&
                      `${detail.base_currency} ${parseCurrency(
                        detail?.transaction_info?.total_recurring_fees
                      )}`}
                  </Grid>
                  {/* Total Upfront Fees */}
                  <Grid item xs={6} md={5}>
                    <Typography variant="subtitle1">
                      Total Upfront Fees
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={7}
                    sx={{
                      overflowWrap: "normal",
                      wordBreak: "break-word",
                    }}
                  >
                    {detail.base_currency &&
                      `${detail.base_currency} ${parseCurrency(
                        detail?.transaction_info?.total_upfront_fees
                      )}`}
                  </Grid>
                  {/*  Annual Percentage Rate */}
                  <Grid item xs={6} md={5}>
                    <Typography variant="subtitle1">
                      Annual Percentage Rate
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={7}
                    sx={{
                      overflowWrap: "normal",
                      wordBreak: "break-word",
                    }}
                  >
                    {detail?.transaction_info?.annual_percentage_rate
                      ? detail?.transaction_info?.annual_percentage_rate
                      : "-"}
                  </Grid>
                  {/*  Last Installment Fee */}
                  <Grid item xs={6} md={5}>
                    <Typography variant="subtitle1">
                      Last Installment Fee
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={7}
                    sx={{
                      overflowWrap: "normal",
                      wordBreak: "break-word",
                    }}
                  >
                    {detail.base_currency &&
                      `${detail.base_currency} ${parseCurrency(
                        detail?.transaction_info?.last_installment_fee
                      )}`}
                  </Grid>
                  {/* Last Installment Amount */}
                  <Grid item xs={6} md={5}>
                    <Typography variant="subtitle1">
                      Last Installment Amount
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={7}
                    sx={{
                      overflowWrap: "normal",
                      wordBreak: "break-word",
                    }}
                  >
                    {detail.base_currency &&
                      `${detail.base_currency} ${parseCurrency(
                        detail?.transaction_info?.last_installment_amount
                      )}`}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    );
  };

  return (
    <>
      <Container>
        <Stack
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h3" sx={{ mb: 5 }}>
            Transaction Details
          </Typography>
          <Button
            startIcon={<Close />}
            size="large"
            variant="outlined"
            onClick={handleButtonClick}
            style={{
              width: "125px",
              alignItems: "center",
            }}
          >
            Back
          </Button>
        </Stack>
        {loaded ? (
          <>
            <Card elevation="2" sx={{ p: 3 }}>
              {renderTransCard(details)}

              <ButtonGroup
                style={{
                  marginTop: "40px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {canBeVoided(details) ? (
                  <Button
                    variant="outlined"
                    sx={{ width: "100px", ml: 2 }}
                    color="error"
                    onClick={handleVoidOpen}
                  >
                    Void
                  </Button>
                ) : canBeRefunded(details) ? (
                  <>
                    <div style={{ width: "15px" }}></div>

                    <Button
                      variant="outlined"
                      sx={{ width: "100px", ml: 2 }}
                      color="error"
                      onClick={handleRefundOpen}
                    >
                      Refund
                    </Button>
                  </>
                ) : null}
              </ButtonGroup>
            </Card>

            {loaded &&
              refs.length > 0 &&
              refs.map((trans) => {
                return (
                  <>
                    <Box sx={{ height: 30 }} />
                    <Card elevation="2">{renderTransCard(trans)}</Card>
                  </>
                );
              })}
          </>
        ) : (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {renderRefundDialog()}
      </Container>
    </>
  );
};

export default TransactionDetails;
