import { useEffect } from "react";
import { useState, useContext, createContext } from "react";
import { login } from "../services/auth.service";

const userContext = createContext(null);

export const UserProvider = ({ page, children, cognitoUser }) => {
  const [user, setUser] = useState({
    email: null,
    role: null,
    access: null,
    refresh: null,
  });
  const [apiSignedIn, setApiSignedIn] = useState(
    localStorage.getItem("user") ? true : false
  );
  useEffect(() => {
    let lsUser = localStorage.getItem("user");
    if (!apiSignedIn) {
      if (cognitoUser) {
        login(cognitoUser?.attributes?.email, setApiSignedIn);
      }
    }
    setUser({
      ...user,
      email: cognitoUser?.attributes?.email || JSON.parse(lsUser)?.email,
      access: JSON.parse(lsUser)?.access,
      refresh: JSON.parse(lsUser)?.refresh,
    });
  }, [apiSignedIn]);

  return <userContext.Provider value={user}>{children}</userContext.Provider>;
};

export const useUserContext = () => useContext(userContext);
