import React, { useEffect, useState } from "react";
import {
  Avatar,
  Container,
  Typography,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  Button as MuButton,
  Card,
  Button,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { apiLogout } from "../services/auth.service";
import { parseEmail } from "../utils/tableFunctions";
import { Edit } from "@mui/icons-material";
import { useUserContext } from "../context/userContext";
// import { getProfile } from "../services/services";

const Profile = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [details, setDetails] = useState({});
  const [loaded, setLoaded] = useState(false);
  const user = useUserContext();

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  // useEffect(() => {
  //     if (!loaded) {
  //         getProfile(setDetails, setLoaded);
  //     }
  // }, [loaded, details]);

  return (
    <>
      <Container>
        <Stack spacing={5} direction="column">
          <Card
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              p: 2,
            }}
          >
            <IconButton
              color="primary"
              style={{ alignSelf: "flex-end" }}
              onClick={handleDialogOpen}
            >
              <LogoutIcon />
            </IconButton>
            <Dialog open={openDialog} onClose={handleDialogClose}>
              <DialogTitle>{"Are you sure you want to log out?"}</DialogTitle>
              <DialogActions>
                <MuButton variant="contained" color="error" onClick={apiLogout}>
                  Log Out
                </MuButton>
                <MuButton variant="contained" onClick={handleDialogClose}>
                  Cancel
                </MuButton>
              </DialogActions>
            </Dialog>
            <Stack spacing={2} direction="column" sx={{ alignItems: "center" }}>
              <Avatar
                src={"https://cdn-icons-png.flaticon.com/512/3135/3135715.png"}
                alt="photoURL"
                sx={{ width: 70, height: 70 }}
              />
              <Typography variant="h5">
                {user.email && parseEmail(user.email)}
              </Typography>
              {loaded &&
                details.merchant_name.map((item) => (
                  <Typography>{item.merchant_name}</Typography>
                ))}
            </Stack>
          </Card>

          <Card
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              p: 2,
            }}
          >
            <Button
              variant="outlined"
              sx={{ alignSelf: "flex-end" }}
              onClick={() => {
                apiLogout();
              }}
              endIcon={<Edit />}
            >
              Change Password
            </Button>

            <Table
              sx={{
                borderColor: "transparent",
                maxWidth: "400px",
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell align="right">
                    <strong>Username</strong>
                  </TableCell>
                  <TableCell align="left">
                    {user.email && parseEmail(user.email)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right">
                    <strong>Email</strong>
                  </TableCell>
                  <TableCell align="left">{user.email && user.email}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
        </Stack>
      </Container>
    </>
  );
};

export default Profile;
