import { Info } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";

const CustomTooltip = ({ content, placement, styles }) => {
  const style = {
    backgroundColor: "#fff",
    margin: "0 auto",
    color: "#000",
    boxShadow: "rgba(100,100,111,0.2) 0px 7px 29px 0px",
    padding: "15px",
    ...styles,
  };
  return (
    <Tooltip
      title={content}
      placement={placement || "right-end"}
      componentsProps={{ tooltip: { sx: style } }}
    >
      <IconButton sx={{ mt: 2, ml: -1 }}>
        <Info />
      </IconButton>
    </Tooltip>
  );
};

export default CustomTooltip;
