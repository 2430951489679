import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FileDownload } from "@mui/icons-material";
import { getDownloadReport } from "../../services/report_api";

const TabHeader = ({ title, report }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const { id } = useParams();

  const handleDownloadCSV = () => {
    getDownloadReport(
      id,
      report.stt_report_name,
      report.report_date,
      report.brand?.name
    );
  };

  const hidden = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Stack
      direction="row"
      sx={{ display: "flex", justifyContent: "space-between" }}
    >
      <Typography variant="h3" sx={{ mb: 5 }}>
        {title}
      </Typography>

      {!hidden && (
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Button
            size="large"
            variant="outlined"
            sx={{
              width: "150%",
              textAlign: "center",
            }}
            endIcon={<FileDownload />}
            // onClick={handleDialogOpen}
            onClick={handleDownloadCSV}
          >
            Download As xlsx
          </Button>
        </Stack>
      )}

      {/* <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Download Report As:</DialogTitle>
        <DialogContent>
          <Stack direction="column">
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <Button onClick={handleDownloadCSV}>XLSX</Button>
              <Button onClick={handleDownloadCSV} disabled>
                PDF
              </Button>
              <Button onClick={handleDownloadCSV} disabled>
                Email
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog> */}
    </Stack>
  );
};

export default TabHeader;
