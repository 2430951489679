import SearchIcon from "@mui/icons-material/Search";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Toolbar,
  Stack,
  Typography,
  OutlinedInput,
  InputAdornment,
  Button as MuButton,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  getTodayDate,
  parseDate,
  utcToBrowserTimeZone,
} from "../../utils/tableFunctions";
import { CSVLink } from "react-csv";
import Filter from "../table/Filter";
import Search from "@mui/icons-material/Search";
import { useState } from "react";
import { useRef } from "react";
import { LoadingButton } from "@mui/lab";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 70,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 320, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

const CSVButton = ({ filename, getCSVData }) => {
  const csvLink = useRef(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const convertTimeZone = (e) => {
    var newData = [];
    e.forEach((row) => {
      Object.keys(row).map((item) => {
        if (item.toLowerCase().includes("datetime")) {
          row[item] = utcToBrowserTimeZone(row[item]);
        }
      });
      return newData.push(row);
    });
    setData(newData);
  };

  const fetchData = async () => {
    setLoading(true);
    await getCSVData()
      .then((e) => convertTimeZone(e))
      .then(() =>
        setTimeout(() => {
          document.getElementById("csvlink").click();
        }, 100)
      )
      .then(() => setLoading(false));
  };

  return (
    <div>
      <LoadingButton variant="outlined" onClick={fetchData} loading={loading}>
        <FileDownloadIcon sx={{ mr: 1 }} />
        Export CSV
      </LoadingButton>

      <CSVLink
        id="csvlink"
        data={data}
        filename={filename}
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
    </div>
  );
};

export default function ListToolbar({
  numSelected,
  filterName,
  onFilterName,
  filter,
  filterFunction,
  onFilterSubmit,
  loaded,
  setReset,
  getCSVData = false,
  type,
  date,
  search = false,
}) {
  const getFileName = () => {
    return type + "-" + getTodayDate() + ".csv";
  };

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: "primary.main",
          bgcolor: "primary.lighter",
        }),
      }}
    >
      {numSelected > 0 && (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      )}

      <SearchStyle
        sx={{ visibility: search ? "visible" : "hidden" }}
        value={filterName}
        onChange={onFilterName}
        placeholder="Search..."
        startAdornment={
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        }
      />

      {date && (
        <Typography
          sx={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            left: 0,
            right: 0,
          }}
        >{`${parseDate(date[0])}  -  ${parseDate(date[1])}`}</Typography>
      )}

      <Stack direction="row" spacing={1}>
        {getCSVData && (
          <CSVButton filename={getFileName()} getCSVData={getCSVData} />
        )}
        {filter && loaded && (
          <Filter
            renderContent={filterFunction}
            // type={type}
            onSubmit={onFilterSubmit}
            setReset={setReset}
          />
        )}
      </Stack>
    </RootStyle>
  );
}
