import toast from "react-hot-toast";

export const loadingToast = () => {
  toast.loading("Processing...");
};

export const showErrorMsg = (
  error = "Something went wrong, please try again!"
) => {
  toast(error, {
    icon: "❕",
    style: {
      borderRadius: "10px",
      background: "#E02401",
      color: "#fff",
    },
    duration: 5000,
  });
};

export const showRefundSubmitted = () => {
  toast("Refund Request Submitted", {
    icon: "✅",
    style: {
      borderRadius: "10px",
      background: "#F2FFE9",
      color: "#000",
    },
  });
};

export const showVoidSubmitted = () => {
  toast("Void Request Submitted", {
    icon: "✅",
    style: {
      borderRadius: "10px",
      background: "#F2FFE9",
      color: "#000",
    },
  });
};
