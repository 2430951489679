import bcrypt from "bcryptjs";
import utf8 from "utf8";

export function hashEmail(email) {
  const hashed = bcrypt.hashSync(
    utf8.encode(email),
    process.env.REACT_APP_API_SALT_KEY
  );
  return hashed;
}
