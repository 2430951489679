import api from "./api";
import { removeUser } from "./token.service";
import { hashEmail } from "../utils/encryptionUtils";

export const login = async (email, setApiSignedIn) => {
  var username = email;
  var password = hashEmail(email);
  const response = await api.post("/auth/jwt/create/", {
    username,
    password,
  });
  if (response.data.access) {
    response.data.email = email;
    localStorage.setItem("user", JSON.stringify(response.data));
    setApiSignedIn(true);
  }
  return response.data;
};

export const apiLogout = () => {
  // AWS cognito logout endpoint
  // const url = `https://${process.env.REACT_APP_COGNITO_DOMAIN}/logout?cliend_id=${process.env.REACT_APP_COGNITO_POOLS_WEB_CLIENT_ID}
  //   &redirect_uri=${process.env.REACT_APP_COGNITO_CALLBACK_DOMAIN}`;
  // axios.get(url);
  removeUser();
  localStorage.clear();
};
