import moment from "moment";

export const canBeVoided = (details) => {
  if (
    details.oxpay_state === "DENIED" ||
    details.oxpay_state === "ORDER_CLOSED" ||
    details.oxpay_state === "VOIDED" ||
    details.oxpay_state === "USER_PAYING" ||
    details.oxpay_state === "PENDING" ||
    details.oxpay_state === "REFUNDED" ||
    details.oxpay_transaction_type === "VOID" ||
    details.oxpay_transaction_type === "REFUND" ||
    details.oxpay_transaction_type === "REVOKE_ORDER" ||
    details.brand.name.toLowerCase() === "enets" ||
    details.brand.name.toLowerCase() === "paynow" ||
    details.brand.name.toLowerCase() === "scb" ||
    details.brand.name.toLowerCase() === "ocbc" ||
    details.action_done
  )
    return false;
  else if (details.void_valid) return true;
  return false;
};

export const canBeRefunded = (details) => {
  let transactionState = details.transaction_state?.toUpperCase();
  let transactionType = details.transaction_type?.toUpperCase();
  // let paymentMethodName = details.payment_method.name.toUpperCase();

  if (
    transactionType === "CAPTURE" &&
    transactionState === "SUCCESS"
    // &&
    // paymentMethodName === "CARD" &&
    // details.is_refunded === false &&
    // details.is_captured === false
  ) {
    return true;
  } else if (
    transactionType === "SALE" &&
    transactionState === "SUCCESS"
    // &&
    // paymentMethodName === "QR"
  ) {
    return true;
  } else {
    return false;
  }
};
