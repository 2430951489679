import { showErrorMsg, showRefundSubmitted } from "../utils/toast";
import api from "./api";
import { cleanJsonObj } from "./services";

export async function getTransactionList(
  setResponse,
  setLoaded,
  filter,
  download = "false"
) {
  const url = "/dashboard/merchant/reconciliation/filter/";
  filter = cleanJsonObj(filter);
  return await api
    .post(url, {
      ...filter,
      download: download ? "true" : "false",
    })
    .then((res) => {
      setResponse(res.data);
    })
    .catch((err) => {
      showErrorMsg(`Status ${err.response.status}, failed to get data`);
    })
    .finally(() => setLoaded(true));
}

export function getTransactionFilterParams(setParams, setLoaded) {
  const url = "/dashboard/merchant/reconciliation/filter_conditions/";
  api
    .get(url)
    .then((response) => {
      setParams(response.data);
    })
    .catch((err) => {
      showErrorMsg(
        `Status ${err.response.status}, failed to get filter choices`
      );
    })
    .finally(() => setLoaded(true));
}

export async function getTransactionCSV(filter) {
  const url = "/dashboard/merchant/reconciliation/filter/";
  filter = cleanJsonObj(filter);
  const data = await api.post(url, {
    download: "true",
    ...filter,
  });
  return data.data;
}

export function getTransactionDetails(id, setResponse, setRefs, setLoaded) {
  const url = `/dashboard/merchant/reconciliation/details/${id}/`;
  api
    .get(url)
    .then((response) => {
      setResponse(response.data);
      if (response.data.length > 1) {
        const newRef = response.data.slice(1);
        setRefs(newRef);
      }
    })
    .catch((err) => {
      showErrorMsg(
        `Status ${err.response.status}, failed to get transaction page`
      );
    })
    .finally(() => setLoaded(true));
}

export function postRefundTransaction(
  pk,
  refund_amount,
  comment,
  setDetails,
  setRefs,
  setLoaded
) {
  const url = `/dashboard/merchant/reconciliation/refund/`;
  console.log("postRefundTransaction");

  api
    .post(url, {
      pk: pk,
      refund_amount: refund_amount,
      comment: comment,
    })
    .then((response) => {
      if (response.status === 200) {
        showRefundSubmitted();
        if (response.data.transaction_id) {
          getTransactionDetails(
            response.data.transaction_id,
            setDetails,
            setRefs,
            setLoaded
          );
        } else {
          setTimeout(() => window.close(), 1000);
        }
      }
    })
    .catch((err) => {
      showErrorMsg(err.response.data?.detail.toString());
    });
}
