import "./App.css";
import {
  Authenticator,
  Image,
  Text,
  useTheme,
  View,
} from "@aws-amplify/ui-react";
import { UserProvider } from "./context/userContext";
import ThemeConfig from "./theme";
import { GlobalStyles } from "@mui/styled-engine";
import { Toaster } from "react-hot-toast";
import Router from "./routes";
import Amplify from "aws-amplify";
import config from "./aws-exports";
import Logo from "./images/OxpayLogo.png";
Amplify.configure(config);

function App() {
  const components = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image alt="Oxpay logo" src={Logo} width="40%" height="40%" />
        </View>
      );
    },

    Footer() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            &copy; All Rights Reserved
          </Text>
        </View>
      );
    },
  };

  return (
    <Authenticator
      socialProviders={["google"]}
      hideSignUp
      components={components}
    >
      {({ signOut, user }) => (
        <UserProvider cognitoUser={user}>
          <ThemeConfig>
            <GlobalStyles />
            <Toaster />
            <Router signOut={signOut} />
          </ThemeConfig>
        </UserProvider>
      )}
    </Authenticator>
  );
}

export default App;
