import React, { useState } from "react";
import { Container, Typography, Stack, Fab, Box, Tab } from "@mui/material";
import ScrollTop from "../components/ScrollTop";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CustomTooltip from "../components/CustomTooltip";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import SettlementsTransactionList from "../components/settlements/SettlementsTransactionList";
import SecurityDepositList from "../components/settlements/SecurityDepositList";

const Settlements = (props) => {
  const [value, setValue] = useState("Settlements");

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const tooltipContent = (
    <Box>
      <Typography variant="body1">Review transactions settled here</Typography>
    </Box>
  );

  return (
    <>
      <Container>
        <Stack
          direction="row"
          spacing={2}
          style={{
            display: "flex",
            alignItems: "center",
          }}
          sx={{ mb: 3 }}
          id="back-to-top-anchor"
        >
          <Typography variant="h3">Settlements</Typography>
          <CustomTooltip content={tooltipContent} placement="bottom" />
        </Stack>

        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange}>
                <Tab label="Settlements" value="Settlements" />
                <Tab label="Reserves" value="Reserves" />
              </TabList>
            </Box>
            <TabPanel value="Settlements" sx={{ px: 0 }}>
              <SettlementsTransactionList />
            </TabPanel>
            <TabPanel value="Reserves" sx={{ px: 0 }}>
              <SecurityDepositList />
            </TabPanel>
          </TabContext>
        </Box>
      </Container>
      <ScrollTop {...props}>
        <Fab
          sx={[
            {
              "&:hover": {
                color: "#112C4A",
                bgcolor: "#fff",
              },
            },
            { bgcolor: "#112C4A", color: "#fff" },
          ]}
          size="large"
          aria-label="Scroll Back to Top"
        >
          <ArrowUpwardIcon />
        </Fab>
      </ScrollTop>
    </>
  );
};

export default Settlements;
