import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Stack,
  Grid,
  Container,
  Typography,
  Backdrop,
  CircularProgress,
  Card,
  Button,
} from "@mui/material";
import {
  parseCurrency,
  parseBrandName,
  parseDateTime,
} from "../../utils/tableFunctions";
import Label from "../../components/Label";
import { Close } from "@mui/icons-material";
import { getSettlementDetails } from "../../services/settlements_api";

const SettlementDetails = () => {
  const [details, setDetails] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    getSettlementDetails(id, setDetails, setLoaded);
  }, [id]);

  const renderSettlementCard = (detail) => {
    return (
      <Card>
        <Grid
          container
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 2, sm: 2, md: 2 }}
          sx={{ mt: 5 }}
        >
          <Grid item xs={6} md={6}>
            <Card
              elevation="2"
              sx={{ height: "100%", p: 2 }}
              variant="outlined"
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6} md={5}>
                  <Typography variant="subtitle1">Settlement ID</Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={7}
                  sx={{
                    overflowWrap: "normal",
                    wordBreak: "break-word",
                  }}
                >
                  {detail.transaction_id}
                </Grid>

                <Grid item xs={6} md={5}>
                  <Typography variant="subtitle1">MCPTid</Typography>
                </Grid>
                <Grid item xs={6} md={7}>
                  {detail.mcptid}
                </Grid>
                <Grid item xs={6} md={5}>
                  <Typography variant="subtitle1">Reference Number</Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={7}
                  sx={{
                    overflowWrap: "normal",
                    wordBreak: "break-word",
                  }}
                >
                  {detail.reference_number}
                </Grid>
                <Grid item xs={6} md={5}>
                  <Typography variant="subtitle1">Merchant</Typography>
                </Grid>
                <Grid item xs={6} md={7}>
                  {detail.merchant_name}
                </Grid>
                <Grid item xs={6} md={5}>
                  <Typography variant="subtitle1">Transaction Type</Typography>
                </Grid>
                <Grid item xs={6} md={7}>
                  {detail.transaction_type}
                </Grid>

                <Grid item xs={6} md={5}>
                  <Typography variant="subtitle1">
                    Settlement Completed
                  </Typography>
                </Grid>
                <Grid item xs={6} md={7}>
                  <Label
                    variant="ghost"
                    color={
                      detail.status === "Approved"
                        ? "success"
                        : detail.status === "Pending"
                        ? "warning"
                        : "error"
                    }
                  >
                    {detail.status}
                  </Label>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={6} md={6}>
            <Card
              elevation="2"
              sx={{ height: "100%", p: 2 }}
              variant="outlined"
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6} md={5}>
                  <Typography variant="subtitle1">Transaction Time</Typography>
                </Grid>
                <Grid item xs={6} md={7}>
                  {parseDateTime(detail.transaction_datetime)}
                </Grid>
                <Grid item xs={6} md={5}>
                  <Typography variant="subtitle1">Brand</Typography>
                </Grid>
                <Grid item xs={6} md={7}>
                  {parseBrandName(detail.brand.name)}
                </Grid>

                <Grid item xs={6} md={5}>
                  <Typography variant="subtitle1">Request Amount</Typography>
                </Grid>
                <Grid item xs={4} md={7}>
                  {`${detail.request_currency} ${parseCurrency(
                    detail.request_amount
                  )}`}
                </Grid>
                <Grid item xs={6} md={5}>
                  <Typography variant="subtitle1">MDR</Typography>
                </Grid>
                <Grid item xs={4} md={7}>
                  {`${detail.mdr_rate} %`}
                </Grid>
                <Grid item xs={6} md={5}>
                  <Typography variant="subtitle1">MDR Amount</Typography>
                </Grid>
                <Grid item xs={4} md={7}>
                  {detail.mdr_amount}
                </Grid>
                <Grid item xs={6} md={5}>
                  <Typography variant="subtitle1">Paid Amount</Typography>
                </Grid>
                <Grid item xs={4} md={7}>
                  {`${detail.paid_currency} ${parseCurrency(
                    detail.paid_amount
                  )}`}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Card>
    );
  };

  return (
    <>
      <Container>
        <Stack
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h3" sx={{ mb: 5 }}>
            Settlements Details
          </Typography>
          <Button
            startIcon={<Close />}
            size="large"
            variant="outlined"
            onClick={() => window.close()}
            style={{
              width: "125px",
              alignItems: "center",
            }}
          >
            Back
          </Button>
        </Stack>
        {loaded ? (
          renderSettlementCard(details)
        ) : (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Container>
    </>
  );
};

export default SettlementDetails;
