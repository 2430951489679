import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "./layouts";
import Dashboard from "./pages/Dashboard";
import Settlements from "./pages/Settlements";
import Transactions from "./pages/Transactions";
import Report from "./pages/Report";
import Profile from "./pages/Profile";
import TransactionDetails from "./pages/details/TransactionDetails";
import SettlementDetails from "./pages/details/SettlementDetails";
import ReportDetails from "./pages/details/ReportDetails";
import Unauthorised from "./pages/Unauthorised";
import Loading from "./pages/Loading";

export default function Router({ signOut }) {
  const isAuthorisedUser = localStorage.getItem("authorisedUser");

  const authorisedRoutes = useRoutes([
    {
      path: "/",
      element: <DashboardLayout signOut={signOut} />,
      children: [
        {
          path: "dashboard",
          element: <Navigate to="/" replace />,
        },
        {
          path: "/",
          element: <Dashboard />,
        },
        {
          path: "settlement",
          element: <Settlements />,
        },
        {
          path: "details/settlement/:id",
          element: <SettlementDetails />,
        },
        {
          path: "transaction",
          element: <Transactions />,
        },
        {
          path: "details/transaction/:id",
          element: <TransactionDetails />,
        },
        {
          path: "report",
          element: <Report />,
        },
        {
          path: "details/report/:id",
          element: <ReportDetails />,
        },
        {
          path: "profile",
          element: <Profile />,
        },
      ],
    },
    {
      path: "/404",
      element: <Unauthorised signOut={signOut} />,
    },
  ]);

  const unauthorisedRoutes = useRoutes([
    {
      path: "/",
      element: <Unauthorised signOut={signOut} />,
    },
    {
      path: "*",
      element: <Navigate to="/" replace />,
    },
  ]);

  const loadingRoutes = useRoutes([
    {
      path: "/",
      element: <Loading />,
    },
    {
      path: "*",
      element: <Navigate to="/" replace />,
    },
  ]);
  return isAuthorisedUser
    ? authorisedRoutes
    : isAuthorisedUser === null
    ? loadingRoutes
    : unauthorisedRoutes;
}
