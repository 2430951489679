import React, { useEffect, useState } from "react";
import {
  Typography,
  Container,
  Stack,
  Card,
  useMediaQuery,
  Tooltip,
  IconButton,
} from "@mui/material";
import { getChartOverview } from "../../services/dashboard_api";
import { getTodayDate } from "../../utils/tableFunctions";
import DashboardChart from "../charts/DashboardChart";
import { Info } from "@mui/icons-material";

const TransactionOverview = ({ user, tooltips }) => {
  const small = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [overview, setOverview] = useState([]);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (user.access && !loaded) {
      getChartOverview(setOverview, setLoaded);
    }
  }, [user, loaded]);

  const getDate = (count) => {
    var dt =
      overview.length > 0
        ? new Date(overview[0].asofdate)
        : new Date(getTodayDate());
    dt.setDate(dt.getDate() - count);

    return dt;
  };

  var date = new Date();
  var daySinceMon = date.getDay() - 1;
  if (daySinceMon === -1) daySinceMon = 6;

  // Filter a list to get all dates that are after given day
  const filterDay = (arr, day) => {
    const result = arr.filter(
      (item) => new Date(item.asofdate) >= getDate(day)
    );
    return result;
  };

  let filtered = filterDay(overview, daySinceMon);
  let lastweek = overview.filter(
    (item) =>
      new Date(item.asofdate) < getDate(6) &&
      new Date(item.asofdate) >= getDate(7 + daySinceMon)
  );
  let sumAmount = 0;
  // Calculate total amount of this week
  for (let i = 0; i < filtered.length; i++) {
    sumAmount += filtered[i].amount;
  }

  const calculatePercent = () => {
    let lastWeekAmount = 0;

    for (let j = 0; j < lastweek.length; j++) {
      lastWeekAmount += lastweek[j].amount;
    }

    let percentage = ((sumAmount - lastWeekAmount) / lastWeekAmount) * 100;
    return percentage;
  };
  return (
    <Container sx={{ my: 3 }}>
      <Typography variant="h3" sx={{ my: 3 }}>
        Transactions Overview
      </Typography>
      <Stack
        direction="row"
        sx={{ my: 3 }}
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Stack>
          <Typography variant={small ? "h3" : "h2"}>
            {(Math.abs(Number(sumAmount)) / 1.0e6).toFixed(2) + "mil"}
          </Typography>
          <Typography variant="subtitle1">Since Monday</Typography>
        </Stack>
        <Stack>
          {/* Increase is green, decrease is red */}
          {calculatePercent() > 0 ? (
            <Typography color="#64dd17" variant={small ? "h3" : "h2"}>
              +{Number(calculatePercent()).toFixed(2)}%
            </Typography>
          ) : (
            <Typography color="#FF2442" variant={small ? "h3" : "h2"}>
              {Number(calculatePercent()).toFixed(2)}%
            </Typography>
          )}
          <Stack direction="row" alignItems="center">
            <Typography variant="subtitle1">from last week</Typography>
            <Tooltip title={tooltips.overview}>
              <IconButton size="small">
                <Info />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
      <Typography variant="h5">Performance this week</Typography>
      <Stack
        direction={{
          xs: "column",
          sm: "row",
        }}
        spacing={{ xs: 2, sm: 2, md: 2 }}
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Card
          sx={{
            minWidth: "100%",
            display: "flex",
            justifyContent: "center",
            p: 2,
          }}
        >
          <DashboardChart overview={overview} loaded={loaded} />
        </Card>
      </Stack>
    </Container>
  );
};

export default TransactionOverview;
