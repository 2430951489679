import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, IconButton } from "@mui/material";

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={() => navigate("/profile")}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
        }}
      >
        <Avatar
          src={"https://cdn-icons-png.flaticon.com/512/3135/3135715.png"}
          alt="photoURL"
        />
      </IconButton>
    </>
  );
}
