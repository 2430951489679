import {
  Backdrop,
  Card,
  CircularProgress,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getNextPage } from "../../services/services";
import {
  getSettlementSD,
  getSettlementSD_CSV,
  getTotalHoldingAmt,
} from "../../services/settlements_api";
import {
  capitalise,
  parseBrandName,
  parseCurrency,
  parseDate,
} from "../../utils/tableFunctions";
import TableView from "../Table";
import ListToolbar from "../table/ListToolbar";
import Scrollbar from "../table/Scrollbar";

const SecurityDepositList = () => {
  const [reserves, setReserves] = useState([]);
  const [amt, setAmt] = useState(null);
  const [next, setNext] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [tableData, setTableData] = useState();
  const [filterName, setFilterName] = useState();
  const mobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const getCSVData = async () => {
    const data = await getSettlementSD_CSV();
    return data;
  };
  const getNext = async () => {
    if (!next) return;
    const res = await getNextPage({
      url: next,
      filter: {},
    });
    let newRows = formatData(res);
    setTableData((old) => [...old, ...newRows]);
    setNext(res.next ? res.next : false);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const formatData = (input) => {
    let tableData = [];
    input?.results?.forEach(
      ({
        id,
        payout_date,
        brand,
        hold_release,
        currency,
        amount,
        stt_report,
      }) => {
        tableData.push({
          id: id,
          payout_date: parseDate(payout_date),
          hold_release: capitalise(hold_release),
          brand: parseBrandName(brand.name),
          currency: currency,
          deposit:
            hold_release.toLowerCase() === "hold" && parseCurrency(amount),
          credit:
            hold_release.toLowerCase() === "release" && parseCurrency(amount),
          stt_report_ID: stt_report,
        });
      }
    );
    return tableData;
  };

  useEffect(() => {
    getSettlementSD(setReserves, setLoaded, false);
    getTotalHoldingAmt(setAmt);
  }, []);

  useEffect(() => {
    if (reserves) {
      setTableData(formatData(reserves));
      setNext(reserves.next);
    }
  }, [reserves]);

  const totalAmountHeld = () => {
    var str = "Total Amount Held: ";
    if (amt)
      amt.map((grp) => {
        return (str +=
          grp.currency + " " + parseCurrency(grp.net_amount) + " | ");
      });
    else str += "-  ";
    return str.slice(0, str.length - 2);
  };

  return (
    <>
      <Typography variant={mobile ? "subtitle2" : "h5"} sx={{ mb: 1 }}>
        {totalAmountHeld()}
      </Typography>
      <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
        {loaded ? (
          <Card>
            <ListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              filter={false}
              loaded={loaded}
              type="settlements_security_deposit"
              csv={false}
              getCSVData={reserves.count > 0 ? getCSVData : false}
            />

            <Scrollbar>
              <TableView
                rows={tableData}
                getNext={getNext}
                count={reserves.count}
                link={`/details/report`}
                query={filterName}
                setSelectedIDs={[]}
                urlId={["stt_report_ID"]}
              />
            </Scrollbar>
          </Card>
        ) : (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Container>
    </>
  );
};

export default SecurityDepositList;
