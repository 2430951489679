import axios from "axios";
import {
  getLocalAccessToken,
  updateLocalAccessToken,
  getLocalRefreshToken,
} from "./token.service";
import { apiLogout } from "./auth.service";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const refresh = getLocalRefreshToken();

instance.interceptors.request.use(
  (config) => {
    const token = getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/auth/jwt/create" && err.response) {
      // Access token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const rs = await instance.post("/auth/jwt/refresh", {
            refresh,
          });
          const access = rs.data.access;
          updateLocalAccessToken(access);
          return instance(originalConfig);
        } catch (_error) {
          // Refresh token was expired
          if (JSON.parse(localStorage.getItem("user"))?.refresh) {
            apiLogout();
          }
          return Promise.reject(_error);
        }
      }
    } else if (
      // Unauthorised user
      originalConfig.url === "/auth/jwt/create" &&
      err.response.status === 401
    ) {
      apiLogout();
    }
    return Promise.reject(err);
  }
);

export default instance;
