import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { parseCurrency, percentageFormat } from "../../utils/tableFunctions";

const SummaryTable = ({ loaded, table, currency }) => {
  function replaceNull(str) {
    return str === null ? "-" : str;
  }

  function parseNegative(field) {
    return field === "0.00" || field === null
      ? parseCurrency(field)
      : `-${parseCurrency(field)}`;
  }

  return (
    <Table sx={{ minWidth: 650 }}>
      <TableHead>
        <TableRow>
          <TableCell>Type</TableCell>

          <TableCell align="center">No. of Transactions</TableCell>

          <TableCell align="right">Amount</TableCell>
        </TableRow>
      </TableHead>
      {loaded && (
        <TableBody>
          {/* First with three merged rows */}
          <TableRow>
            <TableCell component="th" scope="row" sx={{ fontWeight: "700" }}>
              Sales
            </TableCell>

            <TableCell align="center" rowSpan={3}>
              {replaceNull(table.sales_transaction)}
            </TableCell>

            <TableCell align="right">
              {parseCurrency(table.sales_amount)}
            </TableCell>
          </TableRow>

          {/* Sub row for first row */}
          <TableRow>
            <TableCell component="th" scope="row" sx={{ paddingLeft: "4%" }}>
              Sales Charges @ {currency === null ? "" : currency}
              {parseCurrency(table.sales_charges)}
            </TableCell>

            <TableCell align="right">
              {parseNegative(table.sales_charges_amount)}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell component="th" scope="row" sx={{ paddingLeft: "4%" }}>
              MDR @ {percentageFormat(table.mdr)}
            </TableCell>

            <TableCell align="right">
              {parseNegative(table.mdr_amount)}
            </TableCell>
          </TableRow>

          {/* Second row */}
          <TableRow>
            <TableCell component="th" scope="row" sx={{ fontWeight: "700" }}>
              Refund Transaction
            </TableCell>

            <TableCell align="center" rowSpan={2}>
              {replaceNull(table.refund_transaction)}
            </TableCell>

            <TableCell align="right">
              {parseNegative(table.refund_amount)}
            </TableCell>
          </TableRow>

          {/* Sub row for second row */}
          <TableRow>
            <TableCell component="th" scope="row" sx={{ paddingLeft: "4%" }}>
              Refund Charges @ {currency}
              {parseCurrency(table.refund_charges)}
            </TableCell>

            <TableCell align="right">
              {parseNegative(table.refund_charges_amount)}
            </TableCell>
          </TableRow>

          {/* Third row */}
          <TableRow>
            <TableCell component="th" scope="row" sx={{ fontWeight: "700" }}>
              Chargeback
            </TableCell>

            <TableCell align="center" rowSpan={2}>
              {replaceNull(table.chargeback_transaction)}
            </TableCell>

            <TableCell align="right">
              {parseNegative(table.chargeback_amount)}
            </TableCell>
          </TableRow>

          {/* Sub row for third row */}

          <TableRow>
            <TableCell component="th" scope="row" sx={{ paddingLeft: "4%" }}>
              Chargeback Charges @ {currency}{" "}
              {parseCurrency(table.chargeback_charges)}
            </TableCell>

            <TableCell align="right">
              {parseNegative(table.chargeback_charges_amount)}
            </TableCell>
          </TableRow>

          {/* Fifth row */}
          <TableRow>
            <TableCell component="th" scope="row" sx={{ fontWeight: "700" }}>
              Void
            </TableCell>

            <TableCell align="center">
              {replaceNull(table.void_transaction)}
            </TableCell>

            <TableCell align="right">
              {parseNegative(table.void_amount)}
            </TableCell>
          </TableRow>

          {/* Sixth row */}
          <TableRow>
            <TableCell component="th" scope="row" sx={{ fontWeight: "700" }}>
              Adjustment
            </TableCell>

            <TableCell align="center">
              {replaceNull(table.adjustment_transaction)}
            </TableCell>

            <TableCell align="right">
              {parseNegative(table.adjustment_charges_amount)}
            </TableCell>
          </TableRow>

          {/* Seventh row */}

          {/* Eighth row */}
          <TableRow>
            <TableCell component="th" scope="row" sx={{ fontWeight: "700" }}>
              Rolling Reserve Held
            </TableCell>

            <TableCell align="center"></TableCell>

            <TableCell align="right">
              {parseNegative(table.rolling_reserve_held)}
            </TableCell>
          </TableRow>

          {/* Ninth row */}
          <TableRow>
            <TableCell component="th" scope="row" sx={{ fontWeight: "700" }}>
              Rolling Reserve Released
            </TableCell>

            <TableCell align="center"></TableCell>

            <TableCell align="right">
              {parseCurrency(table.rolling_reserve_release)}
            </TableCell>
          </TableRow>

          {/* 10th row */}

          {/* 11st row */}
          <TableRow>
            <TableCell component="th" scope="row" sx={{ fontWeight: "700" }}>
              Maintenance Fee
            </TableCell>

            <TableCell align="center">
              {replaceNull(table.maintenance_format)}
            </TableCell>

            <TableCell align="right">
              {parseNegative(table.maintenance_fee)}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell colSpan={1} />
            <TableCell colSpan={1} align="right" sx={{ fontWeight: "800" }}>
              Net Amount
            </TableCell>
            <TableCell align="right">
              {parseCurrency(table.net_amount)}
            </TableCell>
          </TableRow>
        </TableBody>
      )}
    </Table>
  );
};

export default SummaryTable;
