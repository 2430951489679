import { useState, useEffect } from "react";
// material
import {
  Box,
  Card,
  Container,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
// components
import Scrollbar from "../table/Scrollbar";
import ListToolbar from "../table/ListToolbar";
import { parseBrandName, formatDateFilter } from "../../utils/tableFunctions";
import {
  DateRangeType,
  MultiCheckboxesType,
  MultiSelect,
} from "../table/Filter";
import moment from "moment";
import { getNextPage } from "../../services/services";
import TableView from "../Table";
import {
  getReportCSV,
  getReportFilterParams,
  getReportList,
} from "../../services/report_api";

const ReportList = () => {
  const [reportList, setReportList] = useState([]);
  const [next, setNext] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [tableData, setTableData] = useState();
  const [filterName, setFilterName] = useState();

  // ------------------ filterStuffies --------------------//
  const [fParams, setFParams] = useState();
  const [fLoaded, setFLoaded] = useState(false);
  const [fMerchantOID, setFMerchantOID] = useState([]);
  const [fBrand, setFBrand] = useState([]);
  const [fDate, setFDate] = useState([moment().subtract(3, "days"), moment()]);
  const [reset, setReset] = useState(false);
  const [currentDate, setCurrentDate] = useState(fDate);

  const filter = {
    start_date: formatDateFilter(fDate[0], "start"),
    end_date: formatDateFilter(fDate[1], "end"),
    merchant_id: fMerchantOID,
    payment_brand: fBrand,
  };

  useEffect(() => {
    if (reset) setReset(false);
  }, [reset]);

  const onFilterSubmit = () => {
    getReportList(setReportList, setLoaded, filter, false);
    setCurrentDate(fDate);
  };
  const filterComponents = () => {
    return (
      fLoaded && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="subtitle1" sx={{ my: 1 }}>
            Merchant Name
          </Typography>
          <MultiSelect
            options={fParams.merchant_name.map((merchant) => ({
              value: merchant,
              label: merchant,
            }))}
            selectedValues={fMerchantOID}
            setSelectedValues={setFMerchantOID}
            reset={reset}
          />
          <Box sx={{ height: 15 }} />

          <Typography variant="subtitle1" sx={{ my: 1 }}>
            Date Range
          </Typography>
          <DateRangeType date={fDate} setDate={setFDate} reset={reset} />
          <Box sx={{ height: 15 }} />

          <Typography variant="subtitle1" sx={{ my: 1 }}>
            Brand
          </Typography>
          <MultiCheckboxesType
            items={fParams.payment_brand}
            checkedValues={fBrand}
            setCheckedValues={setFBrand}
            reset={reset}
          />
          <Box sx={{ height: 15 }} />
        </Box>
      )
    );
  };

  // ------------------ end of filter stuffies --------------------//
  const getCSVData = async () => {
    const data = await getReportCSV(filter);
    return data;
  };

  const getNext = async () => {
    if (!next) return;
    const res = await getNextPage({
      url: next,
      filter: filter,
    });
    let newRows = formatData(res);
    setTableData((old) => [...old, ...newRows]);
    setNext(res.next ? res.next : false);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const formatData = (input) => {
    let tableData = [];
    input?.results?.forEach(
      ({
        id,
        merchant_name,
        report_date,
        brand,
        revision_number,
        report_number,
      }) => {
        tableData.push({
          id: id,
          merchant_name: merchant_name,
          brand: parseBrandName(brand?.name),
          report_number: report_number,
          date: report_date,
          revision_number: revision_number,
        });
      }
    );
    return tableData;
  };

  useEffect(() => {
    getReportFilterParams(setFParams, setFLoaded);
    getReportList(setReportList, setLoaded, filter, false);
  }, []);

  useEffect(() => {
    if (reportList) {
      setTableData(formatData(reportList));
      setNext(reportList.next);
    }
  }, [reportList]);

  return (
    <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
      {loaded ? (
        <Card>
          <ListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            date={currentDate}
            filter={true}
            loaded={loaded}
            type="stt_report"
            filterFunction={filterComponents}
            onFilterSubmit={onFilterSubmit}
            setReset={setReset}
            csv={false}
            getCSVData={reportList.count > 0 ? getCSVData : false}
          />

          <Scrollbar>
            <TableView
              rows={tableData}
              link={`/details/report`}
              getNext={getNext}
              count={reportList.count}
              query={filterName}
              setSelectedIDs={[]}
            />
          </Scrollbar>
        </Card>
      ) : (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Container>
  );
};

export default ReportList;
