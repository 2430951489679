import { useState } from "react";
// material
import { styled } from "@mui/material/styles";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import useMediaQuery from "@mui/material/useMediaQuery";
import ScrollTop from "../components/ScrollTop";
import { Outlet } from "react-router-dom";
import { Fab } from "@mui/material";
import { ArrowUpward } from "@mui/icons-material";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100vh",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

const DashboardLayout = (props) => {
  const [open, setOpen] = useState(false);
  const { signOut, children } = props;
  const hidden = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      {hidden ? null : (
        <DashboardSidebar
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
        />
      )}

      <MainStyle>
        {children || <Outlet />}
        <ScrollTop {...props}>
          <Fab
            sx={[
              {
                "&:hover": {
                  color: "#112C4A",
                  bgcolor: "#fff",
                },
              },
              { bgcolor: "#112C4A", color: "#fff" },
            ]}
            size="large"
            aria-label="Scroll Back to Top"
          >
            <ArrowUpward />
          </Fab>
        </ScrollTop>
      </MainStyle>
    </RootStyle>
  );
};

export default DashboardLayout;
