import { Link, useLocation } from "react-router-dom";

// material
import { alpha, useTheme, styled } from "@mui/material/styles";
import { Box, List, ListItemText, ListItemIcon, Button } from "@mui/material";

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// ----------------------------------------------------------------------

function NavItem({ item, active, userRole }) {
  const theme = useTheme();
  const { title, path, icon, info } = item;

  const defaultStyle = {
    ...theme.typography.body2,
    height: 48,
    position: "relative",
    textTransform: "capitalize",
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    "&:before": {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: "none",
      position: "absolute",
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main,
    },
  };

  const activeRootStyle = {
    ...defaultStyle,
    color: "primary.main",
    fontWeight: "fontWeightMedium",
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    "&:before": { display: "block" },
  };

  return (
    <Button
      component={Link}
      to={path}
      sx={active ? activeRootStyle : defaultStyle}
      fullWidth
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </Button>
  );
}

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();

  return (
    <Box {...other}>
      <List disablePadding>
        {navConfig.map((item) => (
          <NavItem
            key={item.title}
            item={item}
            active={item.path === pathname}
            // userRole={userRole}
          />
        ))}
      </List>
    </Box>
  );
}
