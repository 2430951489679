import React from "react";
import {
  Grid,
  Card,
  Divider,
  Container,
  Typography,
  Stack,
  TableContainer,
  Paper,
} from "@mui/material";

import TabHeader from "./TabHeader";
import { parseCurrency, parseDate } from "../../utils/tableFunctions";
import SummaryTable from "./SummaryTable";

const ReportSummary = ({ id, loaded, summary, report }) => {
  return (
    <>
      <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
        <TabHeader title="Merchant Statement Report" report={report} />
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 2, sm: 2, md: 2 }}
        >
          <Card sx={{ p: 2, width: "100%" }}>
            <Grid container spacing={2} direction="row">
              {loaded && (
                <>
                  <Grid item xs={6} md={5}>
                    <Typography variant="subtitle1">Report Number</Typography>
                  </Grid>
                  <Grid item xs={4} md={7}>
                    {report.report_number}
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Typography variant="subtitle1">Report Date</Typography>
                  </Grid>
                  <Grid item xs={4} md={7}>
                    {parseDate(report.report_date)}
                  </Grid>

                  <Grid item xs={6} md={5}>
                    <Typography variant="subtitle1">Merchant Name</Typography>
                  </Grid>
                  <Grid item xs={4} md={7}>
                    {report.merchant && report.merchant.name}
                  </Grid>
                </>
              )}
            </Grid>
          </Card>
          <Card sx={{ p: 2, width: "100%" }}>
            <Grid container spacing={2} direction="row">
              {loaded && (
                <>
                  <Grid item xs={6} md={5}>
                    <Typography variant="subtitle1">Cycle Range</Typography>
                  </Grid>
                  <Grid item xs={4} md={7}>
                    {parseDate(report.cycle_start_date)} -{" "}
                    {parseDate(report.cycle_end_date)}
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Typography variant="subtitle1">
                      Security Balance
                    </Typography>
                  </Grid>
                  <Grid item xs={4} md={7}>
                    {report.security_balance &&
                      parseCurrency(report.security_balance)}
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Typography variant="subtitle1">Currency</Typography>
                  </Grid>
                  <Grid item xs={4} md={7}>
                    {report.currency}
                  </Grid>
                </>
              )}
            </Grid>
          </Card>
        </Stack>
        <Divider style={{ margin: "20px 0" }} />
        {summary?.length > 0 && (
          <Card sx={{ p: 2 }}>
            <TableContainer component={Paper}>
              <SummaryTable
                loaded={loaded}
                table={summary[0]}
                currency={report.currency}
              />
            </TableContainer>
          </Card>
        )}
      </Container>
    </>
  );
};

export default ReportSummary;
