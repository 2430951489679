import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AnalyticsIcon from "@mui/icons-material/Analytics";
// ----------------------------------------------------------------------

const sidebarConfig = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <DashboardIcon width={22} height={22} />,
  },
  {
    title: "Transactions",
    path: "/transaction",
    icon: <LibraryBooksIcon width={22} height={22} />,
  },
  {
    title: "Settlements",
    path: "/settlement",
    icon: <AccountBalanceIcon width={22} height={22} />,
  },
  {
    title: "Reports",
    path: "/report",
    icon: <AssessmentIcon width={22} height={22} />,
  },

  // {
  //   title: "Insight",
  //   path: "/insight",
  //   icon: <AnalyticsIcon width={22} height={22} />,
  // },
];

export default sidebarConfig;
