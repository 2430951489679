import { useState, useEffect } from "react";
// material
import { Backdrop, Card, CircularProgress, Container } from "@mui/material";
// components
import Scrollbar from "../table/Scrollbar";
import TableView from "../Table";
import {
  parseCurrency,
  parseDate,
  parseTime,
  parseBrandName,
} from "../../utils/tableFunctions";
import { getNextPage } from "../../services/services";
import { getReportSettlements } from "../../services/report_api";

// ----------------------------------------------------------------------

export default function SettlementTable({ id }) {
  const [transaction, setTransaction] = useState([]);
  const [next, setNext] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [tableData, setTableData] = useState();
  const [filterName, setFilterName] = useState();

  const getNext = async () => {
    if (!next) return;
    const res = await getNextPage({
      url: next,
      filter: {},
    });
    let newRows = formatData(res);
    setTableData((old) => [...old, ...newRows]);
    setNext(res.next ? res.next : false);
  };

  const formatData = (input) => {
    let tableData = [];
    input.results.forEach(
      ({
        id,
        transaction_id,
        mcptid,
        transaction_datetime,
        status,
        base_currency,
        request_amount,
        paid_amount,
        brand,
      }) => {
        tableData.push({
          id: id,
          MCPTid: mcptid,
          transaction_id: transaction_id,
          transaction_date: parseDate(transaction_datetime),
          transaction_time: parseTime(transaction_datetime),
          brand: brand ? parseBrandName(brand.name) : "",
          status: status,
          currency: base_currency,
          request_amount: parseCurrency(request_amount),
          paid_amount: parseCurrency(paid_amount),
        });
      }
    );
    return tableData;
  };

  useEffect(() => {
    getReportSettlements(id, setTransaction, setLoaded);
  }, [id]);

  useEffect(() => {
    if (transaction && transaction.count > 0) {
      setTableData(formatData(transaction));
      setNext(transaction.next);
    }
  }, [transaction]);

  return (
    <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
      {loaded ? (
        <Card>
          <Scrollbar>
            <TableView
              rows={tableData || []}
              link={`/details/settlement`}
              getNext={getNext}
              count={transaction.count}
              query={filterName}
              urlId={["transaction_id"]}
            />
          </Scrollbar>
        </Card>
      ) : (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Container>
  );
}
