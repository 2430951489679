import { useState, useEffect } from "react";
// material
import {
  Box,
  Card,
  Container,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
// components
import Scrollbar from "../table/Scrollbar";
import ListToolbar from "../table/ListToolbar";
import {
  parseCurrency,
  parseDate,
  parseBrandName,
  parseTime,
  formatDateFilter,
} from "../../utils/tableFunctions";
import {
  DateRangeType,
  InputType,
  MinMaxType,
  MultiCheckboxesType,
  SingleSelectType,
} from "../table/Filter";
import moment from "moment";
import {
  getSettlementCSV,
  getSettlementFilterParams,
  getSettlementList,
} from "../../services/settlements_api";
import { getNextPage } from "../../services/services";
import TableView from "../Table";

const SettlementsTransactionList = () => {
  const [transactionList, setTransactionList] = useState([]);
  const [next, setNext] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [tableData, setTableData] = useState();
  const [filterName, setFilterName] = useState();

  // ------------------ filterStuffies --------------------//
  const [fParams, setFParams] = useState();
  const [fLoaded, setFLoaded] = useState(false);
  const [fDate, setFDate] = useState([moment().subtract(3, "days"), moment()]);
  const [fMcptid, setFMcptid] = useState("");
  const [fTransId, setFTransId] = useState("");
  const [fStatus, setFStatus] = useState([]);
  const [fBrand, setFBrand] = useState([]);
  const [fCurrency, setFCurrency] = useState([]);
  const [fNetAmount, setFNetAmount] = useState([null, null]);
  const [reset, setReset] = useState(false);
  const [currentDate, setCurrentDate] = useState(fDate);
  const statusOptions = ["pending", "approved", "rejected"];

  const filter = {
    start_datetime: formatDateFilter(fDate[0], "start"),
    end_datetime: formatDateFilter(fDate[1], "end"),
    payment_brand: fBrand,
    currency: fCurrency,
    transaction_state: fStatus,
    min_request_amount: fNetAmount[0],
    max_request_amount: fNetAmount[1],
    mcptid: fMcptid,
    transaction_id: fTransId,
  };

  useEffect(() => {
    if (reset) setReset(false);
  }, [reset]);

  const onFilterSubmit = () => {
    getSettlementList(setTransactionList, setLoaded, filter, false);
    setCurrentDate(fDate);
  };

  const filterComponents = () => {
    return (
      fLoaded && (
        <Container sx={{ overflowX: "auto" }}>
          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1" sx={{ my: 1 }}>
              MCPTid
            </Typography>
            <InputType fInput={fMcptid} setFInput={setFMcptid} reset={reset} />
            <Box sx={{ height: 15 }} />

            <Typography variant="subtitle1" sx={{ my: 1 }}>
              Transaction ID
            </Typography>
            <InputType
              fInput={fTransId}
              setFInput={setFTransId}
              reset={reset}
            />
            <Box sx={{ height: 15 }} />

            <Typography variant="subtitle1" sx={{ my: 1 }}>
              Date Range
            </Typography>
            <DateRangeType date={fDate} setDate={setFDate} reset={reset} />
            <Box sx={{ height: 15 }} />

            <Typography variant="subtitle1" sx={{ my: 1 }}>
              Status
            </Typography>
            <SingleSelectType
              options={statusOptions}
              selected={fStatus}
              setSelected={setFStatus}
              reset={reset}
            />
            <Box sx={{ height: 15 }} />

            <Typography variant="subtitle1" sx={{ my: 1 }}>
              Brand
            </Typography>
            <MultiCheckboxesType
              items={fParams.payment_brand}
              checkedValues={fBrand}
              setCheckedValues={setFBrand}
              reset={reset}
            />
            <Box sx={{ height: 15 }} />

            <Typography variant="subtitle1" sx={{ my: 1 }}>
              Currency
            </Typography>
            <MultiCheckboxesType
              items={fParams.currency}
              checkedValues={fCurrency}
              setCheckedValues={setFCurrency}
              reset={reset}
            />
            <Box sx={{ height: 15 }} />

            <Typography variant="subtitle1" sx={{ my: 1 }}>
              Request Amount
            </Typography>
            <MinMaxType
              minVal={fParams.min_request_amount}
              maxVal={fParams.max_request_amount}
              value={fNetAmount}
              setValue={setFNetAmount}
              reset={reset}
            />
            <Box sx={{ height: 15 }} />
          </Box>
        </Container>
      )
    );
  };

  // ------------------ end of filter stuffies --------------------//
  const getCSVData = async () => {
    const data = await getSettlementCSV(filter);
    return data;
  };

  const getNext = async () => {
    if (!next) return;
    const res = await getNextPage({
      url: next,
      filter: filter,
    });
    let newRows = formatData(res);
    setTableData((old) => [...old, ...newRows]);
    setNext(res.next ? res.next : false);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const formatData = (input) => {
    let tableData = [];
    input?.results?.forEach(
      ({
        id,
        mcptid,
        transaction_id,
        transaction_datetime,
        status,
        base_currency,
        request_amount,
        paid_amount,
        brand,
      }) => {
        tableData.push({
          id: id,
          MCPTid: mcptid,
          transaction_ID: transaction_id,
          transaction_date: parseDate(transaction_datetime),
          transaction_time: parseTime(transaction_datetime),
          brand: brand ? parseBrandName(brand.name) : "",
          status: status,
          currency: base_currency,
          request_amount: parseCurrency(request_amount),
          paid_amount: parseCurrency(paid_amount),
        });
      }
    );
    return tableData;
  };

  useEffect(() => {
    getSettlementFilterParams(setFParams, setFLoaded);
    getSettlementList(setTransactionList, setLoaded, filter, false);
  }, []);

  useEffect(() => {
    if (transactionList) {
      setTableData(formatData(transactionList));
      setNext(transactionList.next);
    }
  }, [transactionList]);

  return (
    <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
      {loaded ? (
        <Card>
          <ListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            date={currentDate}
            filter={true}
            loaded={loaded}
            type="settlements_transactions"
            filterFunction={filterComponents}
            onFilterSubmit={onFilterSubmit}
            setReset={setReset}
            csv={false}
            getCSVData={transactionList.count > 0 ? getCSVData : false}
          />

          <Scrollbar>
            <TableView
              rows={tableData}
              link={`/details/settlement`}
              getNext={getNext}
              count={transactionList.count}
              query={filterName}
              setSelectedIDs={[]}
              urlId={["transaction_ID"]}
            />
          </Scrollbar>
        </Card>
      ) : (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Container>
  );
};

export default SettlementsTransactionList;
