import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { parseBrandName } from "../../utils/tableFunctions";
import moment from "moment";
import { useMediaQuery } from "@mui/material";
import { getBrandColour } from "../../services/services";

const DashboardChart = ({ overview, loaded }) => {
  const mobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [colour, setColour] = useState();

  useEffect(() => {
    getBrandColour(setColour);
  }, []);

  let result = overview.reduce((r, { brand, ...object }) => {
    var temp = r.find((o) => o.brand === brand);
    if (!temp) r.push((temp = { brand, children: [] }));
    temp.children.push(object);
    return r;
  }, []);

  var date = new Date();
  var daySinceMon = date.getDay() - 1;
  if (daySinceMon == -1) daySinceMon = 6;

  // Sum amount of all months per acquirer
  for (let i = 0; i < result.length; i++) {
    let sum = 0;
    for (let j = 0; j < result[i].children.length; j++) {
      sum += result[i].children[j].amount;
    }

    result[i].sum_amount = sum;
  }

  if (!loaded) {
    loaded = true;
  }

  // Sort the acquirer from highest total amount to lowest
  result.sort(function (a, b) {
    return b.sum_amount - a.sum_amount;
  });

  function amountSeries(children) {
    let amount = [0, 0, 0, 0, 0, 0, 0];
    let j = 0;

    for (let day = 0; day < daySinceMon + 1 && j < children.length; day++) {
      if (
        moment(children[j].asofdate).format("YYYY-MM-DD") ===
        moment().subtract(day, "days").format("YYYY-MM-DD")
      ) {
        amount[daySinceMon + 1 - day - 1] = children[j].amount;
        j++;
      }
    }

    return amount;
  }

  let dataSeries = [];
  for (let count = 0; count < result.length; count++) {
    let brand = {};

    brand.name = parseBrandName(result[count].brand);

    brand.type = "bar";
    brand.stack = "total";
    brand.label = {
      show: false,
    };
    brand.emphasis = {
      focus: "series",
    };

    brand.data = amountSeries(result[count].children);

    brand.itemStyle = { color: colour[brand.name].colour_code };
    dataSeries.push(brand);
  }
  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      type: mobile ? "scroll" : "plain",
      // type: "scroll",
      height: "10%",
      padding: [0, 0],
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",

      data: ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"],
    },
    yAxis: {
      type: "value",
      name: overview && overview.length > 0 ? overview[0].currency : "",
    },
    series: dataSeries,
  };
  return (
    loaded && (
      <ReactEcharts
        option={option}
        style={{ height: mobile ? "300%" : "500%", width: "400%" }}
        className="react_for_echarts"
      />
    )
  );
};

export default DashboardChart;
