import { Info } from "@mui/icons-material";
import {
  Typography,
  Container,
  Stack,
  Grid,
  Divider,
  Button,
  Card,
  useMediaQuery,
  Tooltip,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDashboardTransactions } from "../../services/dashboard_api";
import {
  parseBrandName,
  parseCurrency,
  parseDate,
  parseTime,
} from "../../utils/tableFunctions";

const LatestTransactions = ({ user, tooltips }) => {
  const small = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [trans, setTrans] = useState();
  const [extractedTrans, setExtractedTrans] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (user.access && !loaded) {
      getDashboardTransactions(setTrans, setLoaded);
    }
  }, [user, loaded]);

  useEffect(() => {
    // Group by date
    if (loaded && trans.length > 0) {
      var grouped = {};
      trans.forEach(function (val) {
        var date = val.transaction_datetime.split("T")[0];
        if (date in grouped) {
          grouped[date].unshift(val);
        } else {
          grouped[date] = new Array(val);
        }
      });
      setExtractedTrans(grouped);
    }
  }, [trans, loaded]);
  return (
    <Container sx={{ my: 3 }}>
      <Stack
        direction="row"
        display="flex"
        spacing={2}
        alignItems="center"
        sx={{ my: 3 }}
      >
        <Typography variant="h3">Transactions</Typography>
        <Tooltip title={tooltips.transactions}>
          <IconButton>
            <Info />
          </IconButton>
        </Tooltip>
      </Stack>

      <Stack spacing={1}>
        <Card sx={{ p: 2 }}>
          <Stack
            direction="row"
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant={small ? "subtitle2" : "h6"}>Time</Typography>
            <Typography variant={small ? "subtitle2" : "h6"}>
              Currency
            </Typography>
            <Typography variant={small ? "subtitle2" : "h6"}>
              Sales Amount
            </Typography>
            <Typography variant={small ? "subtitle2" : "h6"}>Brand</Typography>
          </Stack>
        </Card>
        {loaded &&
          extractedTrans &&
          Object.keys(extractedTrans).map((date) => (
            <>
              <Divider>
                <Typography
                  variant="h6"
                  sx={{
                    alignSelf: "center",
                    fontWeight: "100",
                  }}
                >
                  {parseDate(date)}
                </Typography>
              </Divider>
              {extractedTrans[date].map((item) => (
                <Card
                  sx={{ p: 2 }}
                  key={item.id}
                  onClick={() => window.open(`/details/transaction/${item.id}`)}
                >
                  <Grid container direction="row" columns={12}>
                    <Grid item xs>
                      <Typography
                        variant="body1"
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {parseTime(item.transaction_datetime)}
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        variant="body1"
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {item.base_currency}
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        variant="body1"
                        style={{
                          textAlign: "right",
                          paddingLeft: "-8px",
                        }}
                      >
                        {parseCurrency(item.base_amount)}
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        variant="body1"
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {parseBrandName(item.brand.name)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              ))}
            </>
          ))}

        <Button>
          <Typography
            variant="body2"
            textAlign="center"
            color="primary"
            onClick={() => navigate("/transaction")}
          >
            See More...
          </Typography>
        </Button>
      </Stack>
    </Container>
  );
};

export default LatestTransactions;
