import { Button, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";

const Unauthorised = ({ signOut }) => {
  return (
    <Container sx={{ textAlign: "center", mt: 8 }}>
      <Typography variant="h3">401 Unauthorised</Typography>
      <Typography variant="subtitle1">
        You must be authenticated to access this page. Please contact Oxpay
        administrator for more details.{" "}
      </Typography>
      <Button variant="contained" onClick={signOut} sx={{ my: 3 }} size="large">
        Sign Out
      </Button>
    </Container>
  );
};

export default Unauthorised;
