import { useState, useEffect } from "react";
// material
import {
  Box,
  Card,
  Container,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
// components
import Scrollbar from "../table/Scrollbar";
import ListToolbar from "../table/ListToolbar";
import {
  parseCurrency,
  parseDate,
  parseBrandName,
  parseTime,
  formatDateFilter,
} from "../../utils/tableFunctions";
import {
  DateRangeType,
  InputType,
  MinMaxType,
  MultiCheckboxesType,
  MultiSelect,
} from "../table/Filter";
import moment from "moment";
import {} from "../../services/settlements_api";
import { getNextPage } from "../../services/services";
import TableView from "../Table";
import {
  getTransactionCSV,
  getTransactionFilterParams,
  getTransactionList,
} from "../../services/transactions_api";

const TransactionList = () => {
  const [transactionList, setTransactionList] = useState([]);
  const [next, setNext] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [tableData, setTableData] = useState();
  const [filterName, setFilterName] = useState();

  // ------------------ filterStuffies --------------------//
  const [fParams, setFParams] = useState();
  const [fLoaded, setFLoaded] = useState(false);
  const [fTransType, setFTransType] = useState([]);
  const [fDate, setFDate] = useState([moment().subtract(3, "days"), moment()]);
  const [fBrand, setFBrand] = useState([]);
  const [fCurrency, setFCurrency] = useState([]);
  const [fBaseAmount, setFBaseAmount] = useState([null, null]);
  const [fMerchantName, setFMerchantName] = useState([]);
  const [fTransId, setFTransId] = useState();
  const [reset, setReset] = useState(false);
  const [currentDate, setCurrentDate] = useState(fDate);

  const filter = {
    merchant_name_list: fMerchantName,
    start_datetime: formatDateFilter(fDate[0], "start"),
    end_datetime: formatDateFilter(fDate[1], "end"),
    payment_brand_list: fBrand,
    base_currency_list: fCurrency,
    transaction_type: fTransType,
    min_base_amount: fBaseAmount[0],
    max_base_amount: fBaseAmount[1],
    transaction_id: fTransId,
  };

  useEffect(() => {
    if (reset) setReset(false);
  }, [reset]);

  const onFilterSubmit = () => {
    getTransactionList(setTransactionList, setLoaded, filter, false);
    setCurrentDate(fDate);
  };

  const filterComponents = () => {
    return (
      fLoaded && (
        <Container sx={{ overflowX: "auto" }}>
          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1" sx={{ my: 1 }}>
              Merchant Name
            </Typography>
            <MultiSelect
              options={fParams.merchant_name.map((name) => ({
                value: name,
                label: name,
              }))}
              setSelectedValues={setFMerchantName}
              selectedValues={fMerchantName}
              reset={reset}
            />
            <Box sx={{ height: 15 }} />

            <Typography variant="subtitle1" sx={{ my: 1 }}>
              Transaction ID
            </Typography>
            <InputType
              fInput={fTransId}
              setFInput={setFTransId}
              reset={reset}
            />
            <Box sx={{ height: 15 }} />

            <Typography variant="subtitle1" sx={{ my: 1 }}>
              Date Range
            </Typography>
            <DateRangeType date={fDate} setDate={setFDate} reset={reset} />
            <Box sx={{ height: 15 }} />

            <Typography variant="subtitle1" sx={{ my: 1 }}>
              Transaction Type
            </Typography>
            <MultiCheckboxesType
              items={fParams.transaction_type}
              checkedValues={fTransType}
              setCheckedValues={setFTransType}
              reset={reset}
            />
            <Box sx={{ height: 15 }} />

            <Typography variant="subtitle1" sx={{ my: 1 }}>
              Brand
            </Typography>
            <MultiCheckboxesType
              items={fParams.payment_brand}
              checkedValues={fBrand}
              setCheckedValues={setFBrand}
              reset={reset}
            />
            <Box sx={{ height: 15 }} />

            <Typography variant="subtitle1" sx={{ my: 1 }}>
              Currency
            </Typography>
            <MultiCheckboxesType
              items={fParams.base_currency}
              checkedValues={fCurrency}
              setCheckedValues={setFCurrency}
              reset={reset}
            />
            <Box sx={{ height: 15 }} />

            <Typography variant="subtitle1" sx={{ my: 1 }}>
              OxPay Gross
            </Typography>
            <MinMaxType
              minVal={fParams.min_base_amount}
              maxVal={fParams.max_base_amount}
              setValue={setFBaseAmount}
              value={fBaseAmount}
              reset={reset}
            />
            <Box sx={{ height: 15 }} />
          </Box>
        </Container>
      )
    );
  };

  // ------------------ end of filter stuffies --------------------//
  const getCSVData = async () => {
    return await getTransactionCSV(filter);
  };

  const getNext = async () => {
    if (!next) return;
    const res = await getNextPage({
      url: next,
      filter: filter,
    });
    let newRows = formatData(res);
    setTableData((old) => [...old, ...newRows]);
    setNext(res.next ? res.next : false);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const formatData = (input) => {
    let tableData = [];
    input?.results?.forEach(
      ({
        id,
        merchant_name,
        mcptid,
        transaction_datetime,
        brand,
        transaction_type,
        base_currency,
        transaction_state,
        base_amount,
      }) => {
        tableData.push({
          id: id,
          transaction_ID: id,
          merchant_name: merchant_name,
          MCPTid: mcptid,
          date: parseDate(transaction_datetime),
          time: parseTime(transaction_datetime),
          brand: brand ? parseBrandName(brand.name) : "",
          type: transaction_type,
          currency: base_currency,
          status: transaction_state,
          paid_amount: parseCurrency(base_amount),
        });
      }
    );
    return tableData;
  };

  useEffect(() => {
    getTransactionFilterParams(setFParams, setFLoaded);
    getTransactionList(setTransactionList, setLoaded, filter, false);
  }, []);

  useEffect(() => {
    if (transactionList) {
      setTableData(formatData(transactionList));
      setNext(transactionList.next);
    }
  }, [transactionList]);
  return (
    <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
      {loaded ? (
        <Card>
          <ListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            date={currentDate}
            filter={true}
            loaded={loaded}
            type="reconciliation_transactions"
            filterFunction={filterComponents}
            onFilterSubmit={onFilterSubmit}
            setReset={setReset}
            csv={false}
            getCSVData={transactionList.count > 0 ? getCSVData : false}
          />

          <Scrollbar>
            <TableView
              rows={tableData}
              link={`/details/transaction`}
              getNext={getNext}
              count={transactionList.count}
              query={filterName}
              setSelectedIDs={[]}
            />
          </Scrollbar>
        </Card>
      ) : (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Container>
  );
};

export default TransactionList;
