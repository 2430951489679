import React from "react";
import {
  Grid,
  Container,
  Typography,
  Stack,
  Card,
  Divider,
} from "@mui/material";
import TabHeader from "./TabHeader";
import { percentageFormat } from "../../utils/tableFunctions";
import useMediaQuery from "@mui/material/useMediaQuery";

const ReportMerchant = ({ id, profile, loaded, summary, currency, report }) => {
  const large = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const fullWidth = large ? "50%" : "100%";

  return (
    <>
      <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
        <TabHeader title="Profile Summary" report={report} />
        {loaded && profile ? (
          <>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 2, sm: 2, md: 3 }}
            >
              {summary && (
                <Card sx={{ p: 2, width: "100%" }}>
                  <Typography variant="h5" sx={{ mb: 3 }}>
                    Fee Structure
                  </Typography>
                  <Grid container spacing={2} direction="row">
                    <Grid item xs={7} md={5}>
                      <Typography>MCP MDR %</Typography>
                    </Grid>
                    <Grid item xs={4} md={3}>
                      <Typography>
                        {summary.mdr && percentageFormat(summary.mdr)}
                      </Typography>
                    </Grid>

                    <Grid item xs={7} md={5}>
                      <Typography>Sales per transaction charge</Typography>
                    </Grid>
                    <Grid item xs={4} md={3}>
                      <Typography>{summary.sales_charges}</Typography>
                    </Grid>

                    <Grid item xs={7} md={5}>
                      <Typography>Chargeback per transaction charge</Typography>
                    </Grid>
                    <Grid item xs={4} md={3}>
                      <Typography>{summary.chargeback_charges}</Typography>
                    </Grid>

                    <Grid item xs={7} md={5}>
                      <Typography>Refund per transaction charge</Typography>
                    </Grid>
                    <Grid item xs={4} md={3}>
                      <Typography>{summary.refund_charges}</Typography>
                    </Grid>
                  </Grid>
                </Card>
              )}
              <Card sx={{ p: 2, width: "100%" }}>
                <Typography variant="h5" sx={{ mb: 3 }}>
                  Settlement Calendar
                </Typography>
                <Grid container spacing={2} direction="row">
                  <Grid item xs={7} md={8}>
                    <Typography>Currency</Typography>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    {currency}
                  </Grid>
                  <Grid item xs={7} md={8}>
                    <Typography>Minimum Payment</Typography>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    {profile.minimum_payout_amount}
                  </Grid>
                  <Grid item xs={7} md={8}>
                    <Typography>Annual Fee Period</Typography>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    {profile.annual_fee_period}
                  </Grid>
                  <Grid item xs={7} md={8}>
                    <Typography>Annual Fee</Typography>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    {profile.annual_fee}
                  </Grid>
                </Grid>
              </Card>
            </Stack>
            <Divider style={{ margin: "20px 0" }} />
            <Card sx={{ p: 2, width: "100%" }}>
              <Typography variant="h5" sx={{ mb: 3 }}>
                Bank Details
              </Typography>
              <Grid container spacing={2} direction="row">
                <Grid item xs={6} md={5}>
                  <Typography>Bank Name</Typography>
                </Grid>
                <Grid item xs={4} md={7}>
                  {profile.bank}
                </Grid>

                <Grid item xs={6} md={5}>
                  <Typography>IBAN</Typography>
                </Grid>
                <Grid item xs={4} md={7}>
                  {profile.iban}
                </Grid>

                <Grid item xs={6} md={5}>
                  <Typography>Beneficiary Name</Typography>
                </Grid>
                <Grid item xs={4} md={7}>
                  {profile.beneficiary_name}
                </Grid>

                <Grid item xs={6} md={5}>
                  <Typography>Beneficiary Address</Typography>
                </Grid>
                <Grid item xs={4} md={7}>
                  {profile.beneficiary_address}
                </Grid>

                <Grid item xs={6} md={5}>
                  <Typography>Beneficiary Bank Code</Typography>
                </Grid>
                <Grid item xs={4} md={7}>
                  {profile.beneficiary_bank_code}
                </Grid>

                <Grid item xs={6} md={5}>
                  <Typography>Account Number</Typography>
                </Grid>
                <Grid item xs={4} md={7}>
                  {profile.account_number}
                </Grid>

                <Grid item xs={6} md={5}>
                  <Typography>Intermediary Name</Typography>
                </Grid>
                <Grid item xs={4} md={7}>
                  {profile.intermediary_name}
                </Grid>
              </Grid>
            </Card>
          </>
        ) : (
          <Typography variant="h4">Profile details cannot be shown.</Typography>
        )}
      </Container>
    </>
  );
};

export default ReportMerchant;
