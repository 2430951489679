import { showErrorMsg } from "../utils/toast";
import api from "./api";
import { cleanJsonObj } from "./services";

export async function getSettlementList(
  setResponse,
  setLoaded,
  filter,
  download = "false"
) {
  const url = "/dashboard/merchant/settlement/filter/";
  filter = cleanJsonObj(filter);
  return await api
    .post(url, {
      ...filter,
      download: download ? "true" : "false",
    })
    .then((res) => {
      setResponse(res.data);
    })
    .catch((err) => {
      showErrorMsg(`Status ${err.response.status}, failed to get data`);
    })
    .finally(() => setLoaded(true));
}

export function getSettlementFilterParams(setParams, setLoaded) {
  const url = "/dashboard/merchant/settlement/filter_conditions/";
  api
    .get(url)
    .then((response) => {
      setParams(response.data);
    })
    .catch((err) => {
      showErrorMsg(
        `Status ${err.response.status}, failed to get filter choices`
      );
    })
    .finally(() => setLoaded(true));
}

export async function getSettlementCSV(filter) {
  const url = "/dashboard/merchant/settlement/filter/";
  filter = cleanJsonObj(filter);
  const data = await api.post(url, {
    download: "true",
    ...filter,
  });
  return data.data;
}

export function getSettlementDetails(id, setResponse, setLoaded) {
  const url = `/dashboard/merchant/settlement/details/${id}/`;
  api
    .get(url)
    .then((response) => {
      setResponse(response.data);
    })
    .catch((err) => {
      showErrorMsg(
        `Status ${err.response.status}, failed to get settlement page`
      );
    })
    .finally(() => setLoaded(true));
}

export function getSettlementSD(setList, setLoaded, download = "false") {
  const url = "/dashboard/merchant/settlement/deposit/details/";
  api
    .post(url, {
      download: download,
    })
    .then((response) => {
      setList(response.data);
      setLoaded(true);
    });
}

export async function getSettlementSD_CSV() {
  const url = "/dashboard/merchant/settlement/filter/";
  const data = await api.post(url, {
    download: "true",
  });
  return data.data;
}

export function getTotalHoldingAmt(setAmt) {
  const url = "/dashboard/merchant/settlement/deposit/total_amount/";
  api.get(url).then((response) => {
    setAmt(response.data);
  });
}
