// format currency
import numeral from "numeral";
import moment from "moment";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function capitalise(str) {
  if (str && str.charAt(0)) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return str;
  }
}

// Format a currency string
export function parseCurrency(number) {
  return numeral(parseFloat(number)).format(
    Number.isInteger(number) ? "0,0.00" : "0,0.00"
  );
}

// Format a date string (DD Mon YYYY)
export function parseDate(date) {
  return moment(date).format("DD MMM YYYY");
}

//

export function parseDateTime(date) {
  return moment.utc(date).local().format("YYYY-MM-DD HH:mm");
}

// Split the string to get only date before T
export function splitDate(date) {
  return date === "-" ? "-" : moment(date).format("DD MMM YYYY HH:mm");
}

// Format a time string (HH:MM)
export function parseTime(date) {
  return moment(date).format("HH:mm");
}

// Get today's date in string (YYYY-MM-DD)
export function getTodayDate() {
  return moment().format("YYYY-MM-DD");
}

export function getDaysBefore(days) {
  return moment().subtract(days, "days").format("YYYY-MM-DD");
}

export function getCurrentMonYear() {
  return moment().format("MMM YYYY");
}

// Parse user's email to get username
export const parseEmail = (emailStr) => {
  var name = emailStr.split("@")[0];
  if (name.includes(".")) {
    name.replace(".", " ");
  }

  return capitalise(name);
};

// Remove '_' symbol and capitalise a brand name
export const parseBrandName = (brand) => {
  return brand;
};

export function percentageFormat(num) {
  return parseFloat(num).toFixed(2) + " %";
}

// Parse values into JSON.stringify
export function stringifyValues(object) {
  let output = {};
  for (const [key, value] of Object.entries(object)) {
    if (value && Array.isArray(value)) {
      output[key] = JSON.stringify(value);
    } else output[key] = value;
  }
  return output;
}

// get date from last Monday
export function getMonday() {
  if (moment().day() === 1) {
    return moment();
  }
  return moment().isoWeekday(1);
}

// format date to 2022-06-18T12:50:57Z - ISO, + local + drop milliseconds
export function formatDateFilter(date, time) {
  if (time === "start") {
    return moment(date).startOf("day").parseZone(date).utc().format();
  } else {
    return moment(date).endOf("day").parseZone(date).utc().format();
  }
}

export function utcToBrowserTimeZone(date) {
  return moment.utc(date).local().format("YYYY-MM-DD HH:mm");
}
