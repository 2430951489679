import React, { useState, useEffect, createContext } from "react";
import { useParams } from "react-router-dom";
import { Container, Typography, Stack, Button, Box, Tab } from "@mui/material";
import ReportSummary from "../../components/report/Summary";
import SettlementTable from "../../components/report/SettlementTable";
import DepositList from "../../components/report/Deposit";
import ReportMerchant from "../../components/report/Merchant";
import CustomTooltip from "../../components/CustomTooltip";
import { Close } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { getReportDetails } from "../../services/report_api";

export const Details = createContext();

const ReportDetails = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [summary, setSummary] = useState([]);
  const [profile, setProfile] = useState([]);
  const [report, setReport] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const [value, setValue] = useState();

  const [tab, setTab] = useState("0");

  const { id } = useParams();

  const handleButtonClick = () => {
    window.close();
  };

  useEffect(() => {
    if (!loaded) {
      getReportDetails(id, setSummary, setProfile, setReport, setLoaded);
      setValue({
        report: report,
        loaded: true,
      });
    }
  }, [loaded, id, report]);

  const reportTooltip = (
    <>
      <Box>
        <Typography>Review settlement reports to merchants</Typography>
      </Box>
    </>
  );
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <Container>
        <Stack
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            style={{
              display: "flex",
              alignItems: "center",
            }}
            sx={{ mb: 3 }}
          >
            <Typography variant="h3">Report Details</Typography>
            <CustomTooltip content={reportTooltip} />
          </Stack>
          <Button
            startIcon={<Close />}
            variant="outlined"
            onClick={handleButtonClick}
            sx={{ height: "100%", mr: 1 }}
          >
            Back
          </Button>
        </Stack>
        {loaded && (
          <Details.Provider value={value}>
            <TabContext value={tab}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChange}>
                  <Tab label="Summary" value="0" />
                  <Tab label="Profile" value="1" />
                  <Tab label="Settlements" value="2" />
                  <Tab label="Security Deposit" value="3" />
                </TabList>
              </Box>
              <TabPanel value="0">
                <ReportSummary
                  id={id}
                  summary={summary}
                  loaded={loaded}
                  report={report}
                />
              </TabPanel>
              <TabPanel value="1">
                <ReportMerchant
                  id={id}
                  profile={profile && profile[0]}
                  loaded={loaded}
                  summary={summary && summary[0]}
                  currency={report.currency}
                  report={report}
                />
              </TabPanel>
              <TabPanel value="2">
                <SettlementTable id={id} report={report} />
              </TabPanel>
              <TabPanel value="3">
                <DepositList id={id} report={report} />
              </TabPanel>
            </TabContext>
          </Details.Provider>
        )}
      </Container>
    </>
  );
};

export default ReportDetails;
