import { useState, useEffect } from "react";
// material
import { Card, Container, Backdrop, CircularProgress } from "@mui/material";
// components
import Scrollbar from "../table/Scrollbar";
import TableView from "../Table";
import { getNextPage } from "../../services/services";
import { getReportDeposit } from "../../services/report_api";

// ----------------------------------------------------------------------

export default function SettlementTable({ id }) {
  const [transaction, setTransaction] = useState();
  const [next, setNext] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [tableData, setTableData] = useState();
  const [filterName, setFilterName] = useState();

  const getNext = async () => {
    if (!next) return;
    const res = await getNextPage({
      url: next,
      filter: {},
    });
    let newRows = formatData(res);
    setTableData((old) => [...old, ...newRows]);
    setNext(res.next ? res.next : false);
  };

  useEffect(() => {
    getReportDeposit(id, setTransaction, setLoaded);
  }, [id]);

  const formatData = (input) => {
    let tableData = [];
    input.results.forEach(
      ({ amount, currency, hold_release, id, brand, payout_date }) => {
        tableData.push({
          id: id,
          amount: amount,
          currency: currency,
          hold_release: hold_release,
          payment_brand: brand?.name,
          payout_date: payout_date,
        });
      }
    );
    return tableData;
  };

  useEffect(() => {
    if (transaction) {
      setTableData(formatData(transaction));
      setNext(transaction.next);
    }
  }, [transaction]);

  return (
    <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
      {loaded ? (
        <Card>
          <Scrollbar>
            <TableView
              rows={tableData || []}
              getNext={getNext}
              count={transaction.count}
              query={filterName}
              clickable={false}
            />
          </Scrollbar>
        </Card>
      ) : (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Container>
  );
}
